<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.0625 8.5C1.0625 10.4725 1.84609 12.3643 3.24089 13.7591C4.63569 15.1539 6.52745 15.9375 8.5 15.9375C10.4725 15.9375 12.3643 15.1539 13.7591 13.7591C15.1539 12.3643 15.9375 10.4725 15.9375 8.5C15.9375 6.52745 15.1539 4.63569 13.7591 3.24089C12.3643 1.84609 10.4725 1.0625 8.5 1.0625C6.52745 1.0625 4.63569 1.84609 3.24089 3.24089C1.84609 4.63569 1.0625 6.52745 1.0625 8.5Z"
      fill="#4CAF50"
    />
    <path
      d="M12.2541 5.17078L7.4374 9.98744L5.45407 8.00411L4.4624 8.99578L7.4374 11.9708L13.2457 6.16244L12.2541 5.17078Z"
      fill="#CCFF90"
    />
  </svg>
</template>
