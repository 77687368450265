import './assets/main.css'
import 'vue-final-modal/style.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'

import App from './App.vue'
import router from './router'
import { client } from './api/client'
import { createVfm } from 'vue-final-modal'
import * as Sentry from "@sentry/vue";


const apiUrl = import.meta.env.VITE_API_URL

client.setConfig({
  baseUrl: apiUrl,
  headers: {
    auth: window.Telegram.WebApp.initData
  }
})



const vfm = createVfm()
const app = createApp(App)


Sentry.init({
  app,
  dsn: "https://bd9e67418ea9d98b356b7870825f7efd@o4508291534815232.ingest.de.sentry.io/4508291544907856",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(createPinia())
app.use(router)
app.use(vfm)
app.use(PrimeVue, { unstyled: false })

app.mount('#app')

function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

if (iOS()) {
  document.body.classList.toggle('iOS')
}
