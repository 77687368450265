import {
  ItemType,
  ToyColor,
  type GetDailyRewardsUserGetDailyRewardsPostResponse,
  type GetShopItemsShopGetResponse,
  type GetUserInventoryUserInventoryGetResponse,
  type Rewards,
  type SetToyOnTreeUserSetToyOnTreePostResponse,
  type ShopSchema,
  type UserInventorySchema_Output,
  type UserTreeSchema
} from '@/api/client'
import { defineStore } from 'pinia'

export type InvItem = {
  storeItem: ShopSchema
  invItems: UserInventorySchema_Output[]
}

type State = {
  // private

  // local

  // from server
  rewards?: Array<Rewards>
}

export const useDrewardsStore = defineStore('drewards', {
  state: (): State => ({
    rewards: undefined
  }),

  getters: {
    days: (state) => {
      if (!state.rewards)
        return {
          1: undefined,
          2: undefined,
          3: undefined,
          4: undefined,
          5: undefined,
          6: undefined,
          7: undefined
        }

      return {
        1: state.rewards.find((item) => item.reward_day === 1),
        2: state.rewards.find((item) => item.reward_day === 2),
        3: state.rewards.find((item) => item.reward_day === 3),
        4: state.rewards.find((item) => item.reward_day === 4),
        5: state.rewards.find((item) => item.reward_day === 5),
        6: state.rewards.find((item) => item.reward_day === 6),
        7: state.rewards.find((item) => item.reward_day === 7)
      }
    }
  },

  actions: {
    setTree(data: GetDailyRewardsUserGetDailyRewardsPostResponse) {
      this.rewards = data
    }
  }
})
