<template>
  <div class="farm ready" v-if="dailyComboStore.status.isCanClaim" @click="claim">
    <div class="content2">
      <img src="/img/coin-mini.png" height="17" width="16" alt="" />
      <span class="amount-val">{{ award }}</span>
    </div>
  </div>
  <div class="farm claimed" v-else-if="dailyComboStore.status.isClimed">
    <div class="content2">
      <img src="/img/coin-mini.png" height="17" width="16" alt="" />
      <span class="amount-val">{{ award }}</span>
      <IconDone />
    </div>
  </div>
  <div class="farm waiting" v-else-if="dailyComboStore.status.isWaiting">
    <div class="content2">
      <img src="/img/coin-mini.png" height="17" width="16" alt="" />
      <span class="amount-val">{{ award }}</span>
    </div>
  </div>
  <div v-else></div>
</template>

<script setup lang="ts">
import { useDailyComboStore } from '@/stores/dailyCombo'
import { computed, ref } from 'vue'
import IconDone from './icons/IconDone.vue'
import {
  claimDailyComboDailyComboClaimDailyComboPost,
  getDailyComboDailyComboGetDailyComboGet
} from '@/api/client'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const dailyComboStore = useDailyComboStore()
const isLoading = ref(false)

const award = computed(() => {
  return `+${(dailyComboStore.data?.award || 0).toLocaleString('en-US')}`
})

const claim = async () => {
  if (isLoading.value) return
  isLoading.value = true

  const { data, error } = await claimDailyComboDailyComboClaimDailyComboPost()
  if (error) return
  userStore.setUser(data)
  await initDailyCombo()

  isLoading.value = false
}

const initDailyCombo = async () => {
  const { data, error } = await getDailyComboDailyComboGetDailyComboGet()
  if (error) return
  dailyComboStore.setData(data)
}
</script>

<style scoped>
.amount {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
.farm {
  border-radius: 8px;
}

.farm.ready {
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
  background: linear-gradient(180deg, #10aa20 0%, #b9d937 100%);
  box-shadow:
    inset 1.66667px -1.66667px 1.66667px rgba(45, 147, 147, 0.6),
    inset -1.66667px 1.66667px 1.66667px rgba(168, 255, 255, 0.6);
  backdrop-filter: blur(25px);
}
.farm.claimed {
}
.farm.waiting {
  padding-left: 4px;
  padding-right: 4px;
  background: linear-gradient(180deg, #4f7e54 0%, #276a2e 100%);
  box-shadow:
    inset 1.66667px 0px 1.66667px rgba(45, 147, 147, 0.6),
    inset 0px 0px 1.66667px rgba(168, 255, 255, 0.6);
  backdrop-filter: blur(2.3px);
}

.amount-val {
  font-weight: 600;
  font-size: 14px;
}
.content2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
}
</style>
