<template>
  <div class="page">
    <h1>Friends: {{ friendsStore.friends?.friends_amount ?? NaN }}</h1>

    <InviteLink v-if="friendsStore.friends" :link="friendsStore.friends.ref_link" />

    <h2>My friends:</h2>

    <div v-if="!isLoading && friendsStore.friends?.friends_list.length" class="list">
      <template v-for="(item, i) of friendsStore.friends?.friends_list" :key="i">
        <FriendItem :item="item" @claim="claimHandler" />
      </template>
    </div>

    <div class="empty" v-else-if="!isLoading">You haven’t invited anyone yet</div>

    <BackButton @click="$router.push({ name: 'home' })" />
  </div>
</template>

<script setup lang="ts">
import {
  claimFriendAwardUserFriendsClaimAwardPost,
  friendsUserFriendsGet,
  type ClaimFriendAwardUserFriendsClaimAwardPostData,
  type FriendAwardNotFound,
  type FriendSchema
} from '@/api/client'
import FriendItem from '@/components/Friends/FriendItem.vue'
import InviteLink from '@/components/Friends/InviteLink.vue'
import { useFriendsStore } from '@/stores/friends'
import { onMounted, ref } from 'vue'
import { BackButton } from 'vue-tg'

const isLoading = ref(true)
const isClaiming = ref(false)
const friendsStore = useFriendsStore()

onMounted(async () => {
  if (friendsStore.friends === null) {
    await initFriends()
  }
  isLoading.value = false
})

const initFriends = async () => {
  const { data, error } = await friendsUserFriendsGet()
  if (error) return
  friendsStore.setFriends(data)
}

const claimHandler = async (item: FriendSchema) => {
  if (isClaiming.value) return
  isClaiming.value = true

  const payload: ClaimFriendAwardUserFriendsClaimAwardPostData = {
    query: { friend_id: item.id }
  }

  const { data, error, response } = await claimFriendAwardUserFriendsClaimAwardPost(payload)

  if (error && response.status === 404) {
    const errData = error as FriendAwardNotFound
    alert(errData.msg_for_user)
    return
  }

  if (error) return

  friendsStore.setFriends(data)
  isClaiming.value = false
}
</script>

<style scoped>
h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  text-align: center;
  color: #fefefe;
  padding: 24px;
}

h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-top: 30px;
  margin-bottom: 12px;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.empty {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #a7a7a7;
}
</style>
