<template>
  <button
    class="btn-reset farm"
    :disabled="status.isWaiting"
    :class="{
      ready: status.isReadyStart,
      claim: status.isReadyClaim,
      waiting: status.isWaiting
    }"
    @click="clickHandler()"
  >
    <div class="content" v-if="status.isReadyStart">Start farming</div>
    <div class="content" v-if="status.isReadyClaim">
      Claim
      <img style="margin-left: 6px" src="/img/coin-mini.png" width="16" height="17" alt="" />
      +{{ (userStore.user?.amount_will_earned || 0).toLocaleString('en-US') }}
    </div>
    <div class="content" v-if="status.isWaiting">
      Farming
      <img style="margin-left: 6px" src="/img/coin-mini.png" width="16" height="17" alt="" />
      +{{ (userStore.user?.amount_will_earned || 0).toLocaleString('en-US') }}

      <!-- +{{
        (
          (userStore.remainingFarmingTime.progress || 0) * (userStore.user?.amount_will_earned || 0)
        ).toLocaleString('en-US', { maximumFractionDigits: 0 })
      }} -->

      <div class="timer">{{ userStore.remainingFarmingTime.string }}</div>
    </div>
  </button>
</template>

<script setup lang="ts">
import {
  claimAutoFarmTapItClaimAutoFarmPost,
  startAutoFarmTapItStartAutoFarmPost
} from '@/api/client'
import { useUserStore } from '@/stores/user'
import { computed } from 'vue'

const userStore = useUserStore()

const status = computed(
  (): { isReadyStart: boolean; isWaiting: boolean; isReadyClaim: boolean } => {
    if (!userStore.user) {
      return { isReadyStart: false, isWaiting: false, isReadyClaim: false }
    }

    const isFirst = !userStore.user.auto_farming_status
    const timer = userStore.remainingFarmingTime.seconds

    return {
      isReadyStart: isFirst,
      isReadyClaim: !isFirst && timer <= 0,
      isWaiting: !isFirst && timer > 0
    }
  }
)

const clickHandler = async () => {
  if (status.value.isReadyStart) await startHandler()
  else if (status.value.isReadyClaim) await claimHandler()
}

const startHandler = async () => {
  const { data, error } = await startAutoFarmTapItStartAutoFarmPost()
  if (error) return
  userStore.setWaitingAutoFarmForUser(data)
}
const claimHandler = async () => {
  const { data, error } = await claimAutoFarmTapItClaimAutoFarmPost()
  if (error) return
  userStore.setUser(data)
}
</script>

<style scoped>
.farm {
  display: block;
  width: 100%;
  height: 44px;
  border-radius: 8px;
}

.farm.ready {
  cursor: pointer;
  background: linear-gradient(180deg, #10aa20 0%, #b9d937 100%);
  box-shadow:
    inset 1.66667px -1.66667px 1.66667px rgba(45, 147, 147, 0.6),
    inset -1.66667px 1.66667px 1.66667px rgba(168, 255, 255, 0.6);
  backdrop-filter: blur(25px);
}
.farm.claim {
  cursor: pointer;
  background: linear-gradient(180deg, #00b312 0%, #007b0c 100%);
  box-shadow:
    inset 1.66667px 0px 1.66667px rgba(45, 147, 147, 0.6),
    inset 0px 0px 1.66667px rgba(168, 255, 255, 0.6);
  backdrop-filter: blur(2.3px);
}
.farm.waiting {
  background: linear-gradient(180deg, #4f7e54 0%, #276a2e 100%);
  box-shadow:
    inset 1.66667px 0px 1.66667px rgba(45, 147, 147, 0.6),
    inset 0px 0px 1.66667px rgba(168, 255, 255, 0.6);
  backdrop-filter: blur(2.3px);
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
}
.ready .content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}
.claim .content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.waiting .content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.content .timer {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);

  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #aba7b1;
}
</style>
