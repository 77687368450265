<template>
  <img :src="ballImgPath" alt="" />
</template>

<script setup lang="ts">
import { type ToyColor } from '@/api/client'
import { computed } from 'vue'
import GradientBorderBox from '../GradientBorderBox.vue'

type Grade = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11

const props = defineProps<{
  color: ToyColor
  grade: number
}>()

const colors: Record<ToyColor, number> = {
  RED: 1,
  YELLOW: 2,
  GREEN: 3,
  BLUE: 4,
  PURPLE: 5
}
const ballImgPath = computed((): string => {
  // const { item_level, item_type, item_color } = props.item

  const id = (props.grade - 1) * 5 + colors[props.color]

  return `/img/balls/ball_${id}.png`
})

// const getColorById
</script>

<style scoped>
/* .item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 80px;
}

img {
  max-width: 68px;
  max-height: 68px;
} */
</style>
