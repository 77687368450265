import type { TapItUserUserInfoSchema } from '@/api/client'
import { defineStore } from 'pinia'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import { useUserStore } from './user'

type State = {
  _userStore: ReturnType<typeof useUserStore>
  userInfo?: TapItUserUserInfoSchema
  used_energy: number

  _currentDate?: any
  _timerId?: number
}

export const useTapItStore = defineStore('tapIt', {
  state: (): State => ({
    userInfo: undefined,
    used_energy: 0,

    _userStore: useUserStore(),
    _currentDate: undefined,
    _timerId: undefined
  }),

  getters: {
    coinBoostRemainingTime(state) {
      dayjs.extend(utc)
      dayjs.extend(timezone)
      dayjs.extend(duration)
      const timestampEnd = Math.floor(state._userStore.user?.coin_boost_end_date || 0)
      const endDate = dayjs.unix(timestampEnd)
      const diff = endDate.diff(state._currentDate)
      const durationObj = dayjs.duration(diff)
      return {
        seconds: durationObj.asSeconds()
      }
    }
  },

  actions: {
    setUserInfo(data: TapItUserUserInfoSchema) {
      this.userInfo = data
      this.refreshCurrentDate()
    },
    setEnergy() {
      this.used_energy = this.userInfo?.energy_max || 0
    },
    updateEnergy(value: number) {
      this.used_energy = value
    },
    updateMaxEnergy(value: number) {
      if (!this.userInfo) return
      this.userInfo.energy_max = value
    },
    deincrementEnergy(step: number) {
      this.used_energy -= step
    },
    incrementEnergy(step: number) {
      this.used_energy += step
    },

    refreshCurrentDate() {
      this._currentDate = dayjs()
      window.clearInterval(this._timerId)
      this._timerId = setInterval(() => {
        this._currentDate = dayjs()
      }, 1000)
    }
  }
})
