<script setup lang="ts">
import type { ShopSchema } from '@/api/client'
import { useShopStore } from '@/stores/shop'
import { useUserStore } from '@/stores/user'
import { computed } from 'vue'

const userStore = useUserStore()
const shopStore = useShopStore()

type BoostState = {
  max: number
  current: number
}

const coinBoost = computed((): BoostState => {
  return {
    max: 3,
    current: userStore.user?.coin_boost || 0
  }
})
const energyBoost = computed((): BoostState => {
  return {
    max: 3,
    current: userStore.user?.energy_boost || 0
  }
})

const buyBoosterHandler = (item: ShopSchema | undefined) => {
  if (!item) return
  emit('buyBooster', item)
}

const emit = defineEmits<{
  (e: 'buyBooster', item: ShopSchema): void
  (e: 'activeBoostEnergy'): void
  (e: 'activeBoostCoins'): void
}>()
</script>

<template>
  <div class="b-tree">
    <div class="b-row">
      <div class="booster">
        <img src="/img/boost/lock.png" alt="" />
      </div>
    </div>

    <div class="b-row">
      <div class="booster" @click="coinBoost.current > 0 && emit('activeBoostCoins')">
        <div class="b-card">
          <div class="b-t">Boost</div>
          <div class="b-v" style="color: white">{{ coinBoost.current }}/{{ coinBoost.max }}</div>
        </div>
        <img src="/img/boost/boost.png" alt="" />
      </div>
      <div class="booster" @click="energyBoost.current > 0 && emit('activeBoostEnergy')">
        <div class="b-card">
          <div class="b-t">Energy</div>
          <div class="b-v" style="color: white">
            {{ energyBoost.current }}/{{ energyBoost.max }}
          </div>
        </div>
        <img src="/img/boost/energy.png" alt="" />
      </div>
    </div>

    <div class="b-row">
      <div class="booster" @click="buyBoosterHandler(shopStore.nextUserBooster.ENERGY_MILK)">
        <img src="/img/boost/milk.png" alt="" />
        <div class="b-card">
          <div class="b-t">Energy milk</div>
          <div class="b-v">{{ (userStore.user?.energy_milk_level || 0) + 1 }} lvl</div>
        </div>
        <div class="price" v-if="shopStore.nextUserBooster.ENERGY_MILK">
          <img src="/img/coin-mini.png" alt="" />
          {{ shopStore.nextUserBooster.ENERGY_MILK.item_price }}
        </div>
      </div>
      <div class="booster" @click="buyBoosterHandler(shopStore.nextUserBooster.MAGIC_TAP)">
        <img src="/img/boost/tap.png" alt="" />
        <div class="b-card">
          <div class="b-t">Magic tap</div>
          <div class="b-v">{{ (userStore.user?.magic_tap_level || 0) + 1 }} lvl</div>
        </div>
        <div class="price" v-if="shopStore.nextUserBooster.MAGIC_TAP">
          <img src="/img/coin-mini.png" alt="" />
          {{ shopStore.nextUserBooster.MAGIC_TAP.item_price }}
        </div>
      </div>

      <div class="booster" @click="buyBoosterHandler(shopStore.nextUserBooster.RECHARGING_SPEED)">
        <img src="/img/boost/recharging.png" alt="" />
        <div class="b-card">
          <div class="b-t">Recharging<br />speed</div>
          <div class="b-v">{{ (userStore.user?.recharging_speed_level || 0) + 1 }} lvl</div>
        </div>
        <div class="price" v-if="shopStore.nextUserBooster.RECHARGING_SPEED">
          <img src="/img/coin-mini.png" alt="" />
          {{ shopStore.nextUserBooster.RECHARGING_SPEED.item_price }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.b-tree {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  user-select: none;
}

.b-tree::before {
  position: absolute;
  content: '';
  z-index: -1;

  top: 0;
  left: 0;
  right: 0;
  bottom: -20%;

  background-image: url(/img/boost/bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.b-row {
  display: flex;
  justify-content: center;
  gap: 12px;
}
.booster {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
}

.booster img {
  width: 92px;
  height: 92px;
}
.b-card {
  background-color: #1b6e6bdb;
  border: 1px solid #fff3;
  height: 50px;
  border-radius: 20% / 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.b-t {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
.b-v {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #a7a7a7;
}

.price {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a7a7a7;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.price img {
  width: 16px;
  height: 17px;
}
</style>
