<template>
  <div class="page">
    <div class="list">
      <BallItem
        style="cursor: pointer"
        v-for="(item, i) in shopStore.balls[selectedColor]"
        :key="i"
        :color="item.item_color || selectedColor"
        :grade="item.item_level"
        :price="item.item_price"
        @click="openPurchase(item)"
      />
    </div>

    <SelectColor v-model="selectedColor" />
  </div>
</template>

<script setup lang="ts">
import {
  buyToyShopBuyToyPost,
  getShopItemsShopGet,
  getUserInventoryUserInventoryGet,
  setToyOnTreeUserSetToyOnTreePost,
  ToyColor,
  type BuyToyShopBuyToyPostData,
  type SetToyOnTreeUserSetToyOnTreePostData,
  type ShopSchema
} from '@/api/client'
import BoosterToyBuyModal from '@/components/Modal/BoosterToyBuyModal.vue'
import BoosterTreeInvModal from '@/components/Modal/BoosterTreeInvModal.vue'
import BallItem from '@/components/upgrades/BallItem.vue'
import SelectColor from '@/components/upgrades/SelectColor.vue'
import { useInventoryStore } from '@/stores/inventory'
import { useShopStore } from '@/stores/shop'
import { useTreeStore } from '@/stores/tree'
import { useUserStore } from '@/stores/user'
import { onMounted, ref } from 'vue'
import { useModal } from 'vue-final-modal'

const shopStore = useShopStore()
const treeStore = useTreeStore()
const inventoryStore = useInventoryStore()
const userStore = useUserStore()

const selectedColor = ref<ToyColor>(ToyColor.RED)
const selectedItem = ref<ShopSchema>()

onMounted(async () => {
  if (!shopStore.shop.length) await initShop()
  if (!inventoryStore.inventory.length) await initInvent()
})

const {
  open: openBuyModal,
  close: closeBuyModal,
  patchOptions
} = useModal({
  component: BoosterToyBuyModal,
  attrs: {
    price: 0,
    async onConfirm() {
      await buyItem()
      closeBuyModal()
      await openTreeInvModal()
    },
    onClose() {
      closeBuyModal()
    }
  }
})

const { open: openTreeInvModal, close: closeTreeInvModal } = useModal({
  component: BoosterTreeInvModal,
  attrs: {
    async onSelectSlot(id) {
      await applyItemForTree(id)
      closeTreeInvModal()
    },
    onClose() {
      closeTreeInvModal()
    }
  }
})

const applyItemForTree = async (slotId: number) => {
  if (!selectedItem.value) return

  const item = inventoryStore.getInventBallByShopId(selectedItem.value.id)
  const slot = slotId

  if (!item) return

  const payload: SetToyOnTreeUserSetToyOnTreePostData = {
    query: {
      inventory_id: item.id,
      tree_slot: slot,
      extended: true
    }
  }
  const { data, error } = await setToyOnTreeUserSetToyOnTreePost(payload)
  if (error) return
  treeStore.setTree(data)
}

const openPurchase = (item: ShopSchema) => {
  selectedItem.value = item
  patchOptions({
    attrs: {
      price: selectedItem.value.item_price
    }
  })
  // isOpen.value = true
  openBuyModal()
}

const buyItem = async () => {
  const item = selectedItem.value
  if (!item) return

  const payload: BuyToyShopBuyToyPostData = {
    query: { toy_id: item.id, extended: true }
  }
  const { data, error } = await buyToyShopBuyToyPost(payload)

  if (error) return

  inventoryStore.setInvent(data)
  userStore.updateUserBalance((userStore.user?.coin_balance || 0) - item.item_price)
}
const initInvent = async () => {
  const { data, error } = await getUserInventoryUserInventoryGet({ query: { extended: true } })
  if (error || !data) {
    return
  }
  inventoryStore.setInvent(data)
}
const initShop = async () => {
  const { data, error } = await getShopItemsShopGet()
  if (error || !data) {
    console.log(error)
    return
  }
  shopStore.setShop(data)
}
</script>

<style scoped>
.list {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 14px;
  column-gap: 10px;
}
</style>
