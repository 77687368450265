import {
  ItemType,
  ToyColor,
  type GetShopItemsShopGetResponse,
  type GetUserInventoryUserInventoryGetResponse,
  type SetToyOnTreeUserSetToyOnTreePostResponse,
  type ShopSchema,
  type UserInventorySchema_Output,
  type UserTreeSchema
} from '@/api/client'
import { defineStore } from 'pinia'

export type InvItem = {
  storeItem: ShopSchema
  invItems: UserInventorySchema_Output[]
}

type State = {
  // private

  // local

  // from server
  tree: Array<UserTreeSchema> | null
}

export const useTreeStore = defineStore('tree', {
  state: (): State => ({
    tree: null
  }),

  getters: {},

  actions: {
    setTree(data: SetToyOnTreeUserSetToyOnTreePostResponse) {
      this.tree = data
    }
  }
})
