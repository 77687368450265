<template>
  <VueFinalModal
    content-class="absolute bottom-0 w-full p-4 bg-white dark:bg-gray-900 space-y-2 main"
    swipe-to-close="down"
    content-transition="vfm-slide-down"
    overlay-transition="vfm-fade"
  >
    <div class="modal-content">
      <button @click="emit('close')" class="btn-reset close">
        <IconClose />
      </button>
      <div class="content" style="padding: 2px 12px 0">
        <div style="text-align: center; margin-bottom: 12px; font-size: 24px; font-weight: 600">
          Add toy:
        </div>
        <div class="tree">
          <TheTree
            :slots="treeStore.tree || []"
            @click-slot="(id) => selectHandler(id)"
            :isClickable="isSeletable"
          />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import IconClose from '../icons/IconClose.vue'
import { onMounted, ref } from 'vue'
import TheTree from '../Tree/TheTree.vue'
import { useTreeStore } from '@/stores/tree'
import { getUserTreeUserTreeGet, type GetUserTreeUserTreeGetData } from '@/api/client'

const isSeletable = ref()
const treeStore = useTreeStore()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'selectSlot', id: number): void
}>()

onMounted(async () => {
  isSeletable.value = true

  if (treeStore.tree === null) {
    await initTree()
  }
})

const initTree = async () => {
  const payload: GetUserTreeUserTreeGetData = {
    query: { extended: true }
  }
  const { data, error } = await getUserTreeUserTreeGet(payload)

  if (error) return

  treeStore.setTree(data)
}

const selectHandler = (id: number) => {
  isSeletable.value = false
  emit('selectSlot', id)
}
</script>

<style>
.main {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>

<style scoped>
.modal-content {
  user-select: none;
  padding: 1rem;
  background-color: aliceblue;

  margin-top: 0.5rem;

  box-sizing: border-box;
  width: 100%;
  color: white;
  background-color: #005559;
  border-radius: 1rem 1rem 0 0;
  outline: 2px solid #b9d937;

  position: relative;
}

.close {
  position: absolute;
  font-size: 24px;
  top: 14px;
  right: 14px;
}

.content {
  display: flex;
  flex-direction: column;
}

.tree {
  position: relative;
  height: 400px;
}
.tree > * {
  margin: 0 auto;
  cursor: pointer;
}
</style>
