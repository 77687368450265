<template>
  <div class="page">
    <div class="list" v-if="inventoryStore.ballsStackedInventory.length > 0">
      <BallItem
        style="cursor: pointer"
        v-for="(item, i) in inventoryStore.ballsStackedInventory"
        :key="i"
        :color="item.storeItem.item_color || ToyColor.BLUE"
        :grade="item.storeItem.item_level"
        :count="item.invItems.length"
        @click="clickItemHandler(item)"
      />
    </div>

    <div v-else style="text-align: center; opacity: 0.8; padding-top: 1rem">
      It's empty here yet
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import {
  getUserInventoryUserInventoryGet,
  setToyOnTreeUserSetToyOnTreePost,
  ToyColor,
  type SetToyOnTreeUserSetToyOnTreePostData
} from '@/api/client'
import { useInventoryStore, type InvItem } from '@/stores/inventory'
import BallItem from '@/components/upgrades/BallItem.vue'
import { useModal } from 'vue-final-modal'
import BoosterTreeInvModal from '@/components/Modal/BoosterTreeInvModal.vue'
import { useTreeStore } from '@/stores/tree'

const inventoryStore = useInventoryStore()
const selectedInvItem = ref<InvItem>()
const treeStore = useTreeStore()

onMounted(async () => {
  if (!inventoryStore.inventory.length) await initInvent()
})

const initInvent = async () => {
  const { data, error } = await getUserInventoryUserInventoryGet({ query: { extended: true } })
  if (error || !data) {
    return
  }
  inventoryStore.setInvent(data)
}

const clickItemHandler = async (item: InvItem) => {
  selectedInvItem.value = item
  open()
}

const { open, close } = useModal({
  component: BoosterTreeInvModal,
  attrs: {
    async onSelectSlot(id) {
      await applyItemForTree(id)
      close()
    },
    onClose() {
      close()
    }
  }
})

const applyItemForTree = async (slotId: number) => {
  const item = selectedInvItem.value
  const slot = slotId

  if (!item) return

  const payload: SetToyOnTreeUserSetToyOnTreePostData = {
    query: {
      inventory_id: item.invItems[0].id,
      tree_slot: slot,
      extended: true
    }
  }
  const { data, error } = await setToyOnTreeUserSetToyOnTreePost(payload)
  if (error) return

  treeStore.setTree(data)
  await initInvent()
}
</script>

<style></style>

<style scoped>
.list {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 14px;
  column-gap: 10px;
}
</style>
