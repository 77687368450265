<template>
  <VueFinalModal
    content-class="absolute bottom-0 w-full p-4 bg-white dark:bg-gray-900 space-y-2 main"
    swipe-to-close="down"
    content-transition="vfm-slide-down"
    overlay-transition="vfm-fade"
  >
    <div class="modal-content">
      <button @click="emit('close')" class="btn-reset close">
        <IconClose />
      </button>
      <div class="content" style="padding: 2px 12px 40px">
        <div style="text-align: center; margin-bottom: 24px; font-size: 24px; font-weight: 600">
          Are you sure?
        </div>
        <button
          class="btn-reset btn-active"
          @click="confirmHandler"
          :disabled="!canBuy || isLoading"
        >
          <img src="/img/coin-mini.png" width="14" height="15" alt="" />
          {{ price.toLocaleString('uk-UA') }}
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user'
import { VueFinalModal } from 'vue-final-modal'
import IconClose from '../icons/IconClose.vue'
import { computed, onMounted, ref } from 'vue'

const isLoading = ref()
const userStore = useUserStore()

const props = defineProps<{
  price: number
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'confirm'): void
}>()

onMounted(() => {
  isLoading.value = false
})

const canBuy = computed((): boolean => {
  const balance = userStore.user?.coin_balance || 0
  return balance >= props.price
})

const confirmHandler = () => {
  isLoading.value = true
  emit('confirm')
}
</script>

<style>
.main {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>

<style scoped>
.modal-content {
  user-select: none;
  padding: 1rem;
  background-color: aliceblue;

  margin-top: 0.5rem;

  box-sizing: border-box;
  width: 100%;
  color: white;
  background-color: #005559;
  border-radius: 1rem 1rem 0 0;
  outline: 2px solid #b9d937;

  position: relative;
}

.close {
  position: absolute;
  font-size: 24px;
  top: 14px;
  right: 14px;
}

.content {
  display: flex;
  flex-direction: column;
}

.text-xl {
  font-size: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
}
</style>
