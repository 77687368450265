<template>
  <div class="page">
    <BoostTree
      style="margin-top: 2rem"
      @buy-booster="selectForBuyBoosterHandler"
      @active-boost-coins="activeBoostCoinHandler"
      @active-boost-energy="activeBoostEnergyHandler"
    />
  </div>
</template>

<script setup lang="ts">
import {
  buyBoosterShopBuyBoosterPost,
  getShopItemsShopGet,
  getUserInventoryUserInventoryGet,
  useCoinBoostUserUseCoinBoostPost,
  useEnergyBoostUserUseEnergyBoostPost,
  type BoostTurnedResponse,
  type BuyBoosterShopBuyBoosterPostData,
  type ShopSchema
} from '@/api/client'
import BoostTree from '@/components/BoostTree.vue'
import BoosterToyBuyModal from '@/components/Modal/BoosterToyBuyModal.vue'
import { DAILY_COMBO_NUMBER_DISPLAY_DURATION, useDailyComboStore } from '@/stores/dailyCombo'
import { useInventoryStore } from '@/stores/inventory'
import { useShopStore } from '@/stores/shop'
import { useTapItStore } from '@/stores/tapIt'
import { useUserStore } from '@/stores/user'
import { sleep } from '@/utils/sleep'
import { onMounted, ref } from 'vue'
import { useModal } from 'vue-final-modal'
import { useRouter } from 'vue-router'

const shopStore = useShopStore()
const userStore = useUserStore()
const inventoryStore = useInventoryStore()
const dailyComboStore = useDailyComboStore()
const tapItStore = useTapItStore()
const router = useRouter()

const selectedItem = ref<ShopSchema>()

onMounted(async () => {
  if (!shopStore.shop.length) await initShop()
  if (!inventoryStore.inventory.length) await initInvent()
})

const initInvent = async () => {
  const { data, error } = await getUserInventoryUserInventoryGet({ query: { extended: true } })
  if (error || !data) {
    return
  }
  inventoryStore.setInvent(data)
}
const initShop = async () => {
  const { data, error } = await getShopItemsShopGet()
  if (error || !data) {
    console.log(error)
    return
  }
  shopStore.setShop(data)
}

const {
  open: openBuyModal,
  close: closeBuyModal,
  patchOptions
} = useModal({
  component: BoosterToyBuyModal,
  attrs: {
    price: 0,
    async onConfirm() {
      await buyItem()
      closeBuyModal()
    },
    onClose() {
      closeBuyModal()
    }
  }
})

const selectForBuyBoosterHandler = async (item: ShopSchema) => {
  patchOptions({
    attrs: { price: item.item_price }
  })
  selectedItem.value = item
  openBuyModal()
}

const activeBoostCoinHandler = async () => {
  if ((userStore.user?.coin_boost || 0) <= 0) return

  const { data, error } = await useCoinBoostUserUseCoinBoostPost()
  if (error) return

  userStore.reduceBoostCoinActivations()
  updateAfterActiveBoost(data)

  await sleep(DAILY_COMBO_NUMBER_DISPLAY_DURATION / 2)
  router.push({ name: 'home' })
}
const activeBoostEnergyHandler = async () => {
  if ((userStore.user?.energy_boost || 0) <= 0) return

  const { data, error } = await useEnergyBoostUserUseEnergyBoostPost()
  if (error) return

  userStore.reduceBoostEnergyActivations()
  updateAfterActiveBoost(data)

  await sleep(DAILY_COMBO_NUMBER_DISPLAY_DURATION / 4)
  router.push({ name: 'home' })
}

const updateAfterActiveBoost = (data: BoostTurnedResponse) => {
  userStore.setUserBoostData(data)
  tapItStore.updateEnergy(data.energy)
  tapItStore.updateMaxEnergy(data.energy_max)

  if (!data.daily_combo_number && data.daily_combo_number !== 0) return
  dailyComboStore.showNumber(data.daily_combo_number)
}

const buyItem = async () => {
  const item = selectedItem.value
  if (!item) return
  const payload: BuyBoosterShopBuyBoosterPostData = {
    query: { item_id: item.id }
  }
  const { data, error } = await buyBoosterShopBuyBoosterPost(payload)
  if (error) return
  userStore.setUser(data)
}
</script>

<style scoped>
.list {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 14px;
  column-gap: 10px;
}
</style>
