<template>
  <div class="page">
    <div class="text">Accrue coins for logging into the game daily<br />without skipping</div>

    <ClaimProgr />
    <button
      class="claim btn btn-active"
      :disabled="!userStore.user?.can_get_daily_reward || isClaiming"
      @click="claim"
    >
      Claim
    </button>

    <BackButton @click="$router.push({ name: 'home' })" />
  </div>
</template>

<script setup lang="ts">
import {
  claimDailyRewardUserClaimDailyRewardPost,
  getDailyRewardsUserGetDailyRewardsPost
} from '@/api/client'
import ClaimProgr from '@/components/ClaimProgr.vue'
import { useDrewardsStore } from '@/stores/drewards'
import { useUserStore } from '@/stores/user'
import { onMounted, ref } from 'vue'
import { BackButton } from 'vue-tg'

const userStore = useUserStore()
const rewardsStore = useDrewardsStore()
const isClaiming = ref(false)

onMounted(async () => {
  await initRewards()
})

const initRewards = async () => {
  const { data, error } = await getDailyRewardsUserGetDailyRewardsPost()
  if (error) return
  rewardsStore.setTree(data)
}

const claim = async () => {
  isClaiming.value = true
  const { data, error } = await claimDailyRewardUserClaimDailyRewardPost()
  isClaiming.value = false
  if (error) return
  userStore.setUser(data)
}
</script>

<style scoped>
.claim {
  width: 146px;
  height: 34px;
  margin: 0 auto;
  cursor: pointer;
  user-select: none;
  margin-top: 22px;

  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #aba7b1;

  max-width: 305px;
  margin: 24px auto 18px;
}
</style>
