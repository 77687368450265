import { type FriendsSchema } from '@/api/client'
import { defineStore } from 'pinia'

type State = {
  friends: FriendsSchema | null
}

export const useFriendsStore = defineStore('friends', {
  state: (): State => ({
    friends: null
  }),

  actions: {
    setFriends(data: FriendsSchema) {
      this.friends = data
    }
  }
})
