<template>
  <!-- <div>{{ newYearCounter.localNewYear }}</div> -->
  <div class="counter">
    <div class="val">
      <div class="a">{{ newYearCounter.localNewYear.counter.day }}</div>
      <div class="b">DAY</div>
    </div>
    <div class="val">
      <div class="a">{{ newYearCounter.localNewYear.counter.hrs }}</div>
      <div class="b">HRS</div>
    </div>
    <div class="val">
      <div class="a">{{ newYearCounter.localNewYear.counter.min }}</div>
      <div class="b">MIN</div>
    </div>
    <div class="val">
      <div class="a">{{ newYearCounter.localNewYear.counter.sec }}</div>
      <div class="b">SEC</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCounterStore } from '@/stores/newYearCounter'

const newYearCounter = useCounterStore()
newYearCounter.refreshCurrentDate()
</script>

<style scoped>
.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  padding: 4px;

  background: #6abd3211;
  border: 1px solid #6abd32;
  filter: drop-shadow(0px 0px 2px #6abd3244);
  backdrop-filter: blur(2px);
  box-shadow: 0px 1px 2px #0002;
}
.val {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.a {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}
.b {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}
</style>
