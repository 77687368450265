import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import FriendsView from '@/views/FriendsView.vue'
import BoostView from '@/views/BoostView.vue'
import BoostHomeView from '@/views/BoostHomeView.vue'
import BoostInventView from '@/views/BoostInventView.vue'
import BoostShopView from '@/views/BoostShopView.vue'
import StatsView from '@/views/StatsView.vue'
import ClaimView from '@/views/ClaimView.vue'
import ClaimSocialView from '@/views/ClaimSocialView.vue'
import ClaimDailyrewardView from '@/views/ClaimDailyrewardView.vue'
import ClaimDailyComboView from '@/views/ClaimDailyComboView.vue'
import ClaimFriendsView from '@/views/ClaimFriendsView.vue'
import ClaimLeagueView from '@/views/ClaimLeagueView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/friends',
      name: 'friends',
      component: FriendsView
    },
    {
      path: '/stats',
      name: 'stats',
      component: StatsView
    },

    {
      path: '/boost',
      component: BoostView,
      children: [
        { path: '/boost', redirect: '/boost/boost' },
        {
          path: 'boost',
          component: BoostHomeView
        },
        {
          path: 'shop',
          component: BoostShopView
        },
        {
          path: 'invent',
          component: BoostInventView
        }
      ]
    },

    {
      path: '/claim',
      component: ClaimView,
      children: [
        { path: '/claim', redirect: '/claim/dailyreward' },
        {
          path: 'social',
          component: ClaimSocialView
        },
        {
          path: 'dailycombo',
          component: ClaimDailyComboView
        },
        {
          path: 'dailyreward',
          component: ClaimDailyrewardView
        },
        {
          path: 'friends',
          component: ClaimFriendsView
        },
        {
          path: 'league',
          component: ClaimLeagueView
        }
      ]
    }
  ]
})

export default router
