<template>
  <div class="task">
    <div class="top">
      <div class="icon">
        <img class="picture" v-if="item.picture_url" :src="item.picture_url" alt="" />
        <svg
          v-else
          width="60"
          height="60"
          viewBox="0 0 37 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.375 24.6667V30.8333C32.375 31.6848 31.6848 32.375 30.8333 32.375H6.16667C5.31523 32.375 4.625 31.6848 4.625 30.8333V27.75M32.375 24.6667V6.16667C32.375 5.31523 31.6848 4.625 30.8333 4.625H6.16667C5.31523 4.625 4.625 5.31523 4.625 6.16667V27.75M32.375 24.6667L23.8695 18.9963C23.4091 18.6893 22.8196 18.6526 22.3247 18.9001L4.625 27.75"
            stroke="#A4A4A4"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M12.3333 16.9583C14.0362 16.9583 15.4167 15.5778 15.4167 13.875C15.4167 12.1721 14.0362 10.7916 12.3333 10.7916C10.6305 10.7916 9.25 12.1721 9.25 13.875C9.25 15.5778 10.6305 16.9583 12.3333 16.9583Z"
            stroke="#A4A4A4"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="content">
        <div class="name">{{ item.task_title }}</div>
        <div class="data">
          <div class="award">
            <img src="/img/coin-mini.png" width="14" height="15" alt="" />
            +{{ item.task_award.toLocaleString('en-US') }}
          </div>
        </div>
      </div>
      <div class="claim" v-if="!isSocialTask">
        <button class="claim-btn btn btn-active" @click="claimHandler" :disabled="!item.can_claim">
          Claim
        </button>
      </div>
    </div>

    <ProgressBar v-if="isFriendTask" :val="friendsProgress" />
    <ProgressBar v-if="isLeagueTask" :val="leagueProgress" />
  </div>
</template>

<script setup lang="ts">
import { TaskType, type TaskResponseSchema } from '@/api/client'
import ProgressBar from './Friends/ProgressBar.vue'
import { computed } from 'vue'

const claimHandler = () => {
  emit('claim', props.item)
}

const props = defineProps<{
  item: TaskResponseSchema
  friendsAmoutn?: number
  leagueVal?: number
}>()

const isFriendTask = computed(() => {
  return props.item.task_type === TaskType.INVITE_FRIEND_TASK
})
const isLeagueTask = computed(() => {
  return props.item.task_type === TaskType.LEAGUE_TASK
})
const isSocialTask = computed(() => {
  return props.item.task_type === TaskType.SOCIAL_TASK
})
const friendsProgress = computed((): number => {
  if (props.item.task_type !== TaskType.INVITE_FRIEND_TASK) return 0
  if (!props.item.amount_to_achieve) return 0
  if (!props.friendsAmoutn) return 0

  return props.friendsAmoutn / props.item.amount_to_achieve
})

const leagueProgress = computed((): number => {
  if (props.item.task_type !== TaskType.LEAGUE_TASK) return 0
  if (!props.item.amount_to_achieve) return 0
  if (!props.leagueVal) return 0

  return props.leagueVal / props.item.amount_to_achieve
})

const emit = defineEmits<{
  (e: 'claim', item: TaskResponseSchema): void
}>()
</script>

<style scoped>
.task {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;

  background: rgba(0, 85, 89, 0.9);
  border: 1px solid rgba(171, 171, 171, 0.27);
  border-radius: 12px;
}

.top {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;
}

.icon {
  /* height: 60px; */
  /* width: 70px; */
  /* margin-bottom: 5px; */
}

.icon > * {
  display: block;
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.picture {
  /* height: 60px; */
  /* width: auto; */
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 6.5px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.data {
  display: flex;
  gap: 20px;
}

.award {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #a7a7a7;
  display: flex;
  align-items: center;
  gap: 4px;
}

.claim-btn {
  padding: 6.5px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
</style>
