<template>
  <div
    class="--container"
    @click="handleClick"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
    @mouseleave="handleMouseUp"
    @touchstart="handleTouch"
    @touchend="handleTouchEnd"
    :class="{ active: isActive }"
    ref="containerRef"
  >
    <slot />

    <div v-for="tap in taps" :key="tap.id" class="tap-effect" :style="tap.style">
      <div class="counter-num">
        {{ (tapItStore.userInfo?.multi_tap || 0) * boostCoef }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { useTapItStore } from '@/stores/tapIt'
import { updateTapedTapItUpdatePost, type UpdateTapedTapItUpdatePostData } from '@/api/client'

const userStore = useUserStore()
const tapItStore = useTapItStore()
const isSaving = ref(false)

const boostCoef = computed(() => {
  return tapItStore.coinBoostRemainingTime.seconds > 0 ? 2 : 1
})

// Реактивные переменные
const isActive = ref(false)
const isLoading = ref(true)
const noEnergy = ref(false)
const activeTouches = ref(0)

tapItStore.setEnergy()

const taps = ref<{ id: number; style: any }[]>([])
let tapId = 0

const containerRef = ref<HTMLElement | null>(null)

onMounted(async () => {
  await updateEnergy(userStore.taps)
  isLoading.value = false
})

const onUpdateTaps = async (clicks: number) => {
  const body: UpdateTapedTapItUpdatePostData = {
    body: {
      taps: clicks
    }
  }
  const { data, error } = await updateTapedTapItUpdatePost(body)
  if (error) {
    console.log(error)
    return
  }
  return data
}

const updateEnergy = async (taps: number) => {
  isSaving.value = true

  const data = await onUpdateTaps(taps)

  if (
    boostCoef.value === 1 ||
    tapItStore.used_energy === tapItStore.userInfo?.energy_max ||
    tapItStore.used_energy === 0
  ) {
    tapItStore.updateEnergy(data?.energy || 0)
  }

  const newBalance = data?.balance || userStore.user?.coin_balance || 0
  updateBalance(newBalance)

  isSaving.value = false
}

// Обработчик кликов мыши
const handleClick = async (event: MouseEvent) => {
  handleTaps({ mouse: event })
}

// Обработчик касаний для мульти-тач
const handleTouch = async (event: TouchEvent) => {
  handleTaps({ touch: event })
  event.preventDefault()
}

const handleTaps = async (e: { mouse?: MouseEvent; touch?: TouchEvent }) => {
  const onTap = () => {
    if (tapItStore.used_energy - (tapItStore.userInfo?.multi_tap || 0) <= 0) {
      noEnergy.value = true
    } else {
      noEnergy.value = false
    }
    if (noEnergy.value) return

    userStore.incrementBalance((tapItStore.userInfo?.multi_tap || 0) * boostCoef.value)
    if (boostCoef.value === 1) {
      tapItStore.deincrementEnergy((tapItStore.userInfo?.multi_tap || 0) * boostCoef.value)
    }
  }

  if (e.touch) {
    const lastTouch = e.touch.touches[e.touch.touches.length - 1]
    onTap()
    if (!noEnergy.value) showEffect(lastTouch.clientX, lastTouch.clientY)
  } else if (e.mouse) {
    onTap()
    if (!noEnergy.value) showEffect(e.mouse.clientX, e.mouse.clientY)
  } else return

  if (
    userStore.taps >= 100 * (tapItStore.userInfo?.multi_tap || 0) * boostCoef.value &&
    !isSaving.value
  ) {
    updateEnergy(userStore.taps)
    const newBalance = (userStore.user?.coin_balance || 0) + userStore.taps
    updateBalance(newBalance)
    userStore.anulateTaps()
  }
}

const showEffect = (clientX: number, clientY: number) => {
  if (!containerRef.value) return
  const containerRect = containerRef.value.getBoundingClientRect()
  const x = clientX - containerRect.left
  const y = clientY - containerRect.top
  addTapEffect(x, y)
}

// Функция для добавления эффекта касания
const addTapEffect = (x: number, y: number) => {
  const newTap = {
    id: tapId++,
    style: {
      left: `${x}px`,
      top: `${y}px`
    }
  }

  if (taps.value.length > 100) {
    taps.value = taps.value.slice(-50)
  }

  taps.value.push(newTap)

  // Удаление эффекта через 1 секунду
  setTimeout(() => {
    taps.value = taps.value.filter((tap) => tap.id !== newTap.id)
  }, 1000)
}

// Обработчики для анимации контейнера
const handleMouseDown = () => {
  if (noEnergy.value) return
  isActive.value = true
}

const handleMouseUp = () => {
  if (noEnergy.value) return
  isActive.value = false
}

const handleTouchEnd = () => {
  if (noEnergy.value) return
  activeTouches.value -= 1
  if (activeTouches.value === 0) {
    isActive.value = false
  }
}

const updateBalance = (newBalance: number) => {
  userStore.updateUserBalance(newBalance)
  if (tapItStore.userInfo) {
    tapItStore.userInfo.balance = newBalance
  }
}
</script>

<style scoped>
.--container {
  position: relative;
  cursor: pointer;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer; /* указатель мыши для интерактивного элемента */
  transition: transform 0.2s ease-in-out; /* плавный переход для эффекта */
}

/* Анимация уменьшения при активации класса .active */
.container.active {
  transform: scale(0.95); /* уменьшение до 95% размера */
}

.character-img {
  display: flex;
  padding: 25px;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  max-width: 300px;
  max-height: 350px;
}

/* Анимация элемента клика */
.tap-effect {
  user-select: none;
  width: 65px;
  height: 65px;
  position: absolute;
  background: #f3ebe9;
  background-image: linear-gradient(180deg, #10aa20 0%, #b9d937 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(-50%, -50%); */
  animation:
    move-up 1s ease-out forwards,
    fade-out 0.5s ease-out forwards;
}

.counter-num {
  display: flex;
  font-size: 32px;
  font-weight: 500;
  color: #1e1e1e;
}

/* Анимация движения вверх */
@keyframes move-up {
  from {
    transform: translate(-50%, -50%) translateY(0);
  }
  to {
    transform: translate(-50%, -50%) translateY(-35vh);
  }
}

/* Анимация исчезновения */
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
