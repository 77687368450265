<script setup lang="ts">
import { computed } from 'vue'
import GradientBorderBox from './GradientBorderBox.vue'

import { useDrewardsStore } from '@/stores/drewards'
import { ToyColor } from '@/api/client'
import { useUserStore } from '@/stores/user'

const rewardsStore = useDrewardsStore()
const userStore = useUserStore()

const day7res = computed(() => {
  const toy = rewardsStore.days[7]?.reward_toy
  return toy
})
const colors: Record<ToyColor, number> = {
  RED: 1,
  YELLOW: 2,
  GREEN: 3,
  BLUE: 4,
  PURPLE: 5
}
const ballImgPath = computed((): string => {
  const toy = day7res.value
  if (!toy) return ''
  const id = (toy.item_level - 1) * 5 + colors[toy.item_color || ToyColor.GREEN]
  return `/img/balls/ball_${id}.png`
})

const isActiveItem = (itemDay: number) => {
  const strike = userStore.user?.daily_strike || 0
  return strike + 1 >= itemDay
}
</script>

<template>
  <div class="prorg">
    <div class="l">
      <GradientBorderBox
        class="item"
        :class="{ 'active-item': isActiveItem(1) }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="12"
        :border-size="1"
      >
        <div class="day">Day 1</div>

        <img class="coin" src="/img/coin-mini.png" alt="" />
        <div class="res">{{ rewardsStore.days[1]?.reward_amount }}</div>
      </GradientBorderBox>
    </div>

    <div class="l">
      <GradientBorderBox
        class="item"
        :class="{ 'active-item': isActiveItem(2) }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="12"
        :border-size="1"
      >
        <div class="day">Day 2</div>

        <img class="coin" src="/img/coin-mini.png" alt="" />
        <div class="res">{{ rewardsStore.days[2]?.reward_amount }}</div>
      </GradientBorderBox>
      <GradientBorderBox
        class="item"
        :class="{ 'active-item': isActiveItem(3) }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="12"
        :border-size="1"
      >
        <div class="day">Day 3</div>

        <img class="coin" src="/img/coin-mini.png" alt="" />
        <div class="res">{{ rewardsStore.days[3]?.reward_amount }}</div>
      </GradientBorderBox>
    </div>

    <div class="l">
      <GradientBorderBox
        class="item"
        :class="{ 'active-item': isActiveItem(4) }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="12"
        :border-size="1"
      >
        <div class="day">Day 4</div>

        <img class="coin" src="/img/coin-mini.png" alt="" />
        <div class="res">{{ rewardsStore.days[4]?.reward_amount }}</div>
      </GradientBorderBox>
      <GradientBorderBox
        class="item"
        :class="{ 'active-item': isActiveItem(5) }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="12"
        :border-size="1"
      >
        <div class="day">Day 5</div>

        <img class="coin" src="/img/coin-mini.png" alt="" />
        <div class="res">{{ rewardsStore.days[5]?.reward_amount }}</div>
      </GradientBorderBox>
      <GradientBorderBox
        class="item"
        :class="{ 'active-item': isActiveItem(6) }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="12"
        :border-size="1"
      >
        <div class="day">Day 6</div>

        <img class="coin" src="/img/coin-mini.png" alt="" />
        <div class="res">{{ rewardsStore.days[6]?.reward_amount }}</div>
      </GradientBorderBox>
    </div>

    <div class="l">
      <GradientBorderBox
        class="item"
        :class="{ 'active-item': isActiveItem(7) }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="12"
        :border-size="1"
      >
        <div class="day">Day 7</div>

        <img class="toy" :src="ballImgPath" alt="" />
      </GradientBorderBox>
    </div>
  </div>
</template>

<style scoped>
.prorg {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.l {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.item {
  height: 74px;
  width: 80px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.active-item {
  background: linear-gradient(180deg, #10aa20 0%, #b9d937 100%) !important;
  border-width: 0 !important;
}
.day {
  font-weight: 700;
  font-size: 16px;
}
.coin {
  width: 21px;
  height: 22px;
}
.toy {
  /* width: 21px;
  height: 22px; */
  max-width: 45px;
  max-height: 45px;
}
.res {
  font-weight: 700;
  font-size: 14px;
}
</style>
