<template>
  <div class="energy">
    <svg
      width="19"
      height="38"
      viewBox="0 0 19 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="transform: rotate(17deg)"
    >
      <path
        d="M16.87 17.647C16.9302 17.5325 16.959 17.4041 16.9535 17.2748C16.9481 17.1456 16.9086 17.0201 16.839 16.911C16.698 16.681 16.447 16.589 16.177 16.589H10.267L11.54 5.52999C11.583 5.15499 11.35 4.76699 10.989 4.65999C10.8137 4.6075 10.6254 4.61877 10.4575 4.6918C10.2897 4.76483 10.1531 4.89491 10.072 5.05899L2.082 21.114C2.0229 21.2385 1.9951 21.3755 2.00103 21.5132C2.00695 21.6509 2.04642 21.785 2.116 21.904C2.258 22.134 2.508 22.32 2.777 22.32H8.607L7.414 32.962C7.372 33.336 7.603 33.65 7.964 33.757C8.03619 33.7775 8.11098 33.7872 8.186 33.786C8.3296 33.786 8.47038 33.7461 8.59271 33.6709C8.71504 33.5957 8.81414 33.4881 8.879 33.36L16.87 17.647Z"
        fill="#FFEC3E"
        stroke="#014341"
        stroke-width="1.4"
      />
    </svg>

    <span class="text">{{ usedEnergy.toFixed(0) }}/{{ maxEnergy }}</span>
  </div>
</template>

<script setup lang="ts">
import { useTapItStore } from '@/stores/tapIt'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

const tapItStore = useTapItStore()

const maxEnergy = computed(() => tapItStore.userInfo?.energy_max || 0)
const usedEnergy = computed(() => tapItStore.used_energy)
const energyRecoveryRate = computed(() => tapItStore.userInfo?.energy_recovery || 1)
const recoveryInterval = ref<number>()

onMounted(() => {
  restartTimer(energyRecoveryRate.value)
})

onUnmounted(() => {
  stopInterval()
})

watch(energyRecoveryRate, (newVal) => {
  restartTimer(newVal)
})

const restartTimer = (rate: number = 1) => {
  stopInterval()
  startInterval(1000 * rate)
}

const stopInterval = () => {
  if (recoveryInterval.value) {
    clearInterval(recoveryInterval.value)
    recoveryInterval.value = undefined
  }
}
const startInterval = (ms: number) => {
  recoveryInterval.value = setInterval(() => {
    if (usedEnergy.value < maxEnergy.value) restoreEnergy()
  }, ms)
}

const restoreEnergy = () => {
  tapItStore.incrementEnergy(1)
}
</script>

<style scoped>
.energy {
  user-select: none;
  height: 44px;

  background: #e2ba24;
  box-shadow: inset -1.66667px 1.66667px 1.66667px rgba(168, 255, 255, 0.6);
  backdrop-filter: blur(25px);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  /* 1000/1000 */
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #185f20;
}
</style>
