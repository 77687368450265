<template>
  <div class="page">
    <div class="timer">{{ dailyComboStore.remainingTime.string }}</div>

    <div class="claim">
      <DailyComboClaimBtn style="margin-top: 10px" />
    </div>

    <div class="nums">
      <GradientBorderBox
        class="num-item"
        :class="{ 'active-item': selectedNumVal.numberId === 1 && selectedNumVal.isEffect }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="4"
        :border-size="1"
      >
        <InputOtp
          integerOnly
          v-model="num1"
          :length="1"
          :readonly="dailyComboStore.numbers.arr.length > 0"
        />
      </GradientBorderBox>
      <GradientBorderBox
        class="num-item"
        :class="{ 'active-item': selectedNumVal.numberId === 2 && selectedNumVal.isEffect }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="4"
        :border-size="1"
      >
        <InputOtp
          integerOnly
          v-model="num2"
          :length="1"
          :readonly="!(dailyComboStore.numbers.arr.length === 1)"
        />
      </GradientBorderBox>
      <GradientBorderBox
        class="num-item"
        :class="{ 'active-item': selectedNumVal.numberId === 3 && selectedNumVal.isEffect }"
        color1="#B9D937"
        color2="#10AA20"
        bg="#005559"
        :border-radius="4"
        :border-size="1"
      >
        <InputOtp
          integerOnly
          v-model="num3"
          :length="1"
          :readonly="!(dailyComboStore.numbers.arr.length === 2)"
        />
      </GradientBorderBox>
    </div>
    <MainButton class="send" v-if="isShowSave && isCanSand" @click="saveNumberHandler">
      Send number
    </MainButton>

    <BackButton @click="$router.push({ name: 'home' })" />
  </div>
</template>

<script setup lang="ts">
import DailyComboClaimBtn from '@/components/DailyComboClaimBtn.vue'
import GradientBorderBox from '@/components/GradientBorderBox.vue'
import { useDailyComboStore } from '@/stores/dailyCombo'
import { computed, onMounted, ref, watch } from 'vue'
import { BackButton } from 'vue-tg'
import InputOtp from 'primevue/inputotp'
import {
  enterDailyComboNumberDailyComboEnterDailyComboNumberPost,
  type EnterDailyComboNumberDailyComboEnterDailyComboNumberPostData,
  type EnterDailyComboNumberDailyComboEnterDailyComboNumberPostError,
  type TooMuchTryToEnter
} from '@/api/client'
import MainButton from '@/components/MainButton.vue'

const dailyComboStore = useDailyComboStore()

const isShowSave = ref(false)

const num1 = ref<string>()
const num2 = ref<string>()
const num3 = ref<string>()

const selectedNumVal = ref<{
  numberId: number
  value: number
  isEffect: boolean
}>({ numberId: -1, value: -1, isEffect: false })

onMounted(async () => {
  num1.value = dailyComboStore.numbers[1]
  num2.value = dailyComboStore.numbers[2]
  num3.value = dailyComboStore.numbers[3]
})

const isCanSand = computed(() => {
  return dailyComboStore.numbers.arr.length < 3
})

watch(num1, (newVal) => {
  if (Number(newVal) === 0 || (newVal && Number(newVal) > 0)) {
    selectedNumVal.value.value = Number(newVal)
    selectedNumVal.value.numberId = 1
    isShowSave.value = true
  } else isShowSave.value = false
})
watch(num2, (newVal) => {
  if (Number(newVal) === 0 || (newVal && Number(newVal) > 0)) {
    selectedNumVal.value.value = Number(newVal)
    selectedNumVal.value.numberId = 2
    isShowSave.value = true
  } else isShowSave.value = false
})
watch(num3, (newVal) => {
  if (Number(newVal) === 0 || (newVal && Number(newVal) > 0)) {
    selectedNumVal.value.value = Number(newVal)
    selectedNumVal.value.numberId = 3
    isShowSave.value = true
  } else isShowSave.value = false
})

const saveNumberHandler = async () => {
  if (selectedNumVal.value && dailyComboStore.numbers.arr.length < 3) {
    await sendNumber(selectedNumVal.value.value)
  }
}

const sendNumber = async (num: number) => {
  const payload: EnterDailyComboNumberDailyComboEnterDailyComboNumberPostData = {
    query: { number: num }
  }
  const { data, error } = await enterDailyComboNumberDailyComboEnterDailyComboNumberPost(payload)
  if (error) {
    if (isTooMuchTryToEnter(error)) {
      const err = error as TooMuchTryToEnter
      if (err.id === 'wrong_number_of_daily_combo') {
        alert(err.msg_for_user)

        const numId = selectedNumVal.value.numberId
        if (numId === 1) {
          num1.value = undefined
        } else if (numId === 2) {
          num2.value = undefined
        } else if (numId === 3) {
          num3.value = undefined
        }
        selectedNumVal.value = { numberId: -1, value: -1, isEffect: false }

        return
      }

      alert(err.msg_for_user)
    }
    return
  }
  dailyComboStore.setData(data)

  selectedNumVal.value.isEffect = true
  setTimeout(() => {
    selectedNumVal.value.isEffect = false
  }, 1000)
}

function isTooMuchTryToEnter(
  obj: EnterDailyComboNumberDailyComboEnterDailyComboNumberPostError
): obj is TooMuchTryToEnter {
  return 'msg_for_user' in obj
}
</script>

<style>
/* otp */
.num-item .p-inputotp,
.num-item .p-inputotp .p-inputotp-input {
  width: 100%;
  height: 100%;
}

.num-item .p-inputotp .p-inputotp-input {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}

.num-input {
  width: 100%;
  height: 100%;
  background: #000;
}

.num-input .p-inputnumber-input {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>

<style scoped>
.timer {
  margin-top: 30px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: center;
}
.claim {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nums {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

/* input {
  all: unset;
} */
.num-item {
  height: 54px;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  transition: all 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.num-item input {
  width: 100%;
  text-align: center;
}

.active-item {
  top: -5px;
}

.send {
  max-width: 200px;
  margin: 12px auto 0;
}
</style>
