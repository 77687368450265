<template>
  <div>
    <button class="topUp" v-if="longPressedHook" @click="topUp">top up</button>
    <router-view v-slot="{ Component }">
      <Transition mode="out-in">
        <component v-if="!isLoading" :is="Component" />
        <div v-else></div>
      </Transition>
    </router-view>

    <div class="spacing"></div>

    <div class="global-nav-wrap">
      <div class="global-nav display-nav">
        <GlobalNav ref="htmlRefHook" />
      </div>
    </div>

    <ModalsContainer />
  </div>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import GlobalNav from './components/GlobalNav.vue'
import { useUserStore } from './stores/user'
import { useTapItStore } from './stores/tapIt'
import {
  friendsUserFriendsGet,
  getDailyComboDailyComboGetDailyComboGet,
  // topUpBalanceDevTopUpBalanceGet,
  userInfoUserGet
} from './api/client'
import { onBeforeMount, onMounted, ref } from 'vue'
import { ModalsContainer } from 'vue-final-modal'
import { useWebAppTheme, useWebAppViewport } from 'vue-tg'
import { useDailyComboStore } from './stores/dailyCombo'
const { disableVerticalSwipes, expand } = useWebAppViewport()
const { setBackgroundColor, setHeaderColor } = useWebAppTheme()

import { onLongPress } from '@vueuse/core'
import { useFriendsStore } from './stores/friends'
const htmlRefHook = ref<HTMLElement>()
const longPressedHook = ref(false)
function onLongPressCallbackHook(e: PointerEvent) {
  longPressedHook.value = true
  setTimeout(() => {
    resetHook()
  }, 3000)
}
function resetHook() {
  longPressedHook.value = false
}
onLongPress(htmlRefHook, onLongPressCallbackHook, {
  modifiers: {
    prevent: true
  }
})
const topUp = async () => {
  // await topUpBalanceDevTopUpBalanceGet()
  await initUser()
  await initDailyCombo()
}

const isLoading = ref(true)
const userStore = useUserStore()
const tapItStore = useTapItStore()
const friendsStore = useFriendsStore()
const dailyComboStore = useDailyComboStore()

onBeforeMount(() => {
  setHeaderColor('#014341')
  setBackgroundColor('#014341')
  disableVerticalSwipes()
  expand()
})

onMounted(async () => {
  await initUser()
  await initDailyCombo()
  tapItStore.refreshCurrentDate()
  await initFriends()
  isLoading.value = false
})

const initUser = async () => {
  const { data, error } = await userInfoUserGet()
  if (error) return
  userStore.setUser(data)
}
const initFriends = async () => {
  const { data, error } = await friendsUserFriendsGet()
  if (error) return
  friendsStore.setFriends(data)
}

const initDailyCombo = async () => {
  const { data, error } = await getDailyComboDailyComboGetDailyComboGet()
  if (error) return
  dailyComboStore.setData(data)
}
</script>

<style scoped>
.topUp {
  cursor: pointer;
  position: absolute;
  left: 1rem;
  top: 3rem;
  height: 3rem;
  width: 6rem;
  z-index: 999;
}
.spacing {
  height: 100px;
}

@media (min-aspect-ratio: 3.3/4) {
  .display-nav {
    display: none;
  }
}
</style>
