import type {
  DailyComboResponseSchema,
  GetDailyComboDailyComboGetDailyComboGetResponse
} from '@/api/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import { defineStore } from 'pinia'

export const DAILY_COMBO_NUMBER_DISPLAY_DURATION = 3000 // ms

type State = {
  displayNumber: number | null
  data?: DailyComboResponseSchema

  _currentDate?: any
  _timerId?: number
}

export const useDailyComboStore = defineStore('dailyCombo', {
  state: (): State => ({
    displayNumber: null,
    data: undefined,

    _currentDate: undefined,
    _timerId: undefined
  }),

  getters: {
    status(state): { isWaiting: boolean; isCanClaim: boolean; isClimed: boolean } {
      const timer = this.remainingTime.seconds
      const canClaim = !!state.data?.can_claim
      const numsReady = this.numbers.arr.length === 3
      return {
        isWaiting: !canClaim && timer > 0,
        isCanClaim: canClaim,
        isClimed: !canClaim && numsReady
      }
    },
    numbers(state) {
      const numbers = state.data?.entered_numbers || []
      return {
        arr: numbers,
        1: numbers[0] === undefined ? undefined : numbers[0].toString(),
        2: numbers[1] === undefined ? undefined : numbers[1].toString(),
        3: numbers[2] === undefined ? undefined : numbers[2].toString()
      }
    },
    remainingTime(state) {
      dayjs.extend(utc)
      dayjs.extend(timezone)
      dayjs.extend(duration)
      const timestampEnd = Math.floor(state.data?.date_end || 0)
      const endDate = dayjs.unix(timestampEnd)
      const diff = endDate.diff(state._currentDate)

      const durationObj = dayjs.duration(diff)
      if (durationObj.asSeconds() < 0) {
        return {
          seconds: durationObj.asSeconds(),
          string: '00:00:00',
          obj: { h: 0, m: 0, s: 0 }
        }
      }

      const hours = String(durationObj.hours()).padStart(2, '0')
      const minutes = String(durationObj.minutes()).padStart(2, '0')
      const seconds = String(durationObj.seconds()).padStart(2, '0')
      const hoursValue = durationObj.hours()
      const minutesValue = durationObj.minutes()
      const secondsValue = durationObj.seconds()
      const remainingTime = `${hours}:${minutes}:${seconds}`

      return {
        seconds: durationObj.asSeconds(),
        string: remainingTime,
        obj: { h: hoursValue, m: minutesValue, s: secondsValue }
      }
    }
  },

  actions: {
    setData(data: GetDailyComboDailyComboGetDailyComboGetResponse) {
      this.data = data
      this.refreshCurrentDate()
    },
    showNumber(number: number) {
      this.displayNumber = number
      setTimeout(() => {
        this.displayNumber = null
      }, DAILY_COMBO_NUMBER_DISPLAY_DURATION)
    },

    refreshCurrentDate() {
      this._currentDate = dayjs()
      window.clearInterval(this._timerId)
      this._timerId = setInterval(() => {
        this._currentDate = dayjs()
      }, 1000)
    }
  }
})
