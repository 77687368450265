<template>
  <div class="item">
    <GradientBorderBox
      class="ball"
      color1="#B9D937"
      color2="#10AA20"
      bg="#005559"
      :border-radius="12"
      :border-size="1"
    >
      <img :src="ballImgPath" alt="" />
      <div v-if="count !== undefined" class="count">x{{ count }}</div>
    </GradientBorderBox>

    <div v-if="price !== undefined" class="price">
      <img src="/img/coin-mini.png" width="14" height="15" alt="" />
      {{ price.toLocaleString('uk-UA') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ToyColor } from '@/api/client'
import { computed } from 'vue'
import GradientBorderBox from '../GradientBorderBox.vue'

const props = defineProps<{
  color: ToyColor
  grade: number
  price?: number
  count?: number
}>()

const colors: Record<ToyColor, number> = {
  RED: 1,
  YELLOW: 2,
  GREEN: 3,
  BLUE: 4,
  PURPLE: 5
}
const ballImgPath = computed((): string => {
  const id = (props.grade - 1) * 5 + colors[props.color]
  return `/img/balls/ball_${id}.png`
})
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 80px;
}

.ball {
  width: 80px;
  height: 74px;
  background: #005559;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

img {
  max-width: 68px;
  max-height: 68px;
}

.price {
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.count {
  position: absolute;
  right: 4px;
  bottom: 4px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
</style>
