<template>
  <div v-if="!isLoading" class="game-content">
    <div class="counter-pos">
      <NewYearCounter />
    </div>
    <div class="combo">
      <DailyComboInfo />
    </div>
    <div class="balance-home">
      <TopBalance isTapMode :isShow="!isLoading" />
      <LeagueInfo />
    </div>
    <div class="character-area">
      <TapItem class="container">
        <TheTree class="container" :slots="treeStore.tree || []" />
      </TapItem>
    </div>
    <div class="footer-widgets">
      <TapEnergy />
      <TapAutofarm style="margin-top: 10px" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  getUserGameInfoTapItUserInfoGet,
  getUserTreeUserTreeGet,
  updateTapedTapItUpdatePost,
  type GetUserTreeUserTreeGetData,
  type UpdateTapedTapItUpdatePostData
} from '@/api/client'
import DailyComboInfo from '@/components/DailyComboInfo.vue'
import LeagueInfo from '@/components/LeagueInfo.vue'
import NewYearCounter from '@/components/NewYearCounter.vue'
import TapAutofarm from '@/components/TapAutofarm.vue'
import TapEnergy from '@/components/TapEnergy.vue'
import TapItem from '@/components/TapItem.vue'
import TopBalance from '@/components/TopBalance.vue'
import TheTree from '@/components/Tree/TheTree.vue'
import { useTapItStore } from '@/stores/tapIt'
import { useTreeStore } from '@/stores/tree'
import { useUserStore } from '@/stores/user'
import { onBeforeUnmount, onMounted, ref } from 'vue'

const treeStore = useTreeStore()
const tapItStore = useTapItStore()
const userStore = useUserStore()
const isLoading = ref(true)

onMounted(async () => {
  await initTree()
  await initTapUpdate()
  isLoading.value = false
})

const initTree = async () => {
  const payload: GetUserTreeUserTreeGetData = {
    query: { extended: true }
  }
  const { data, error } = await getUserTreeUserTreeGet(payload)
  if (error) return
  treeStore.setTree(data)
}

// TAP IT 👇
onBeforeUnmount(async () => {
  await saveClicks()
})
const initTapUpdate = async () => {
  const { data, error } = await getUserGameInfoTapItUserInfoGet()
  if (error) return
  tapItStore.setUserInfo(data)
}
const onUpdateTaps = async (clicks: number) => {
  const body: UpdateTapedTapItUpdatePostData = {
    body: { taps: clicks }
  }
  const { data, error } = await updateTapedTapItUpdatePost(body)
  if (error) return
  return data
}
const updateEnergy = async () => {
  const data = await onUpdateTaps(userStore.taps)
  tapItStore.updateEnergy(data?.energy || 0)
  userStore.updateUserBalance(data?.balance || userStore.user?.coin_balance || 0)
}
const saveClicks = async () => {
  await updateEnergy()
  userStore.anulateTaps()
}
// TAP IT 👆
</script>

<style scoped>
.game-content {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  width: 100%;
  overflow: hidden;
  /* .spacing 100px */
  height: calc(var(--tg-viewport-height) - 100px);
  padding-top: 12px;
  position: relative;
}
.counter-pos {
  position: absolute;
  max-width: 350px;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}
.combo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.balance-home {
  margin-top: 18px;
  margin-bottom: 4px;
}

.character-area {
  margin: 0 auto;
  position: relative;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.footer-widgets {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-widgets > * {
  width: 100%;
  max-width: 351px;
}
</style>
