<template>
  <div class="page">
    <template v-if="!isLoading">
      <div class="list" v-if="taskData?.length">
        <TaskItem
          style="cursor: pointer"
          v-for="(item, i) of taskData"
          :key="i"
          :item="item"
          @click="openTaskHandler(item)"
        />
      </div>

      <div class="empty" v-else>Your task list is empty</div>
    </template>
    <BackButton @click="$router.push({ name: 'home' })" />
  </div>
</template>

<script setup lang="ts">
import {
  getTaskTaskGetTaskGet,
  TaskType,
  userInfoUserGet,
  type GetTaskTaskGetTaskGetData,
  type TaskResponseSchema
} from '@/api/client'
import TaskSocialModal from '@/components/Modal/TaskSocialModal.vue'
import TaskItem from '@/components/TaskItem.vue'
import { useUserStore } from '@/stores/user'
import { onMounted, ref } from 'vue'
import { useModal } from 'vue-final-modal'
import { BackButton } from 'vue-tg'

const taskData = ref<Array<TaskResponseSchema>>()
const isLoading = ref(true)
const userStore = useUserStore()

onMounted(async () => {
  await initTasks()
  isLoading.value = false
})

const { open, close, patchOptions } = useModal({
  component: TaskSocialModal,
  attrs: {
    async onClaim(data) {
      taskData.value = data
      await initUser()
      close()
    },
    onClose() {
      close()
    }
  }
})

const initTasks = async () => {
  const payload: GetTaskTaskGetTaskGetData = {
    query: { type_task: TaskType.SOCIAL_TASK }
  }
  const { data, error } = await getTaskTaskGetTaskGet(payload)
  if (error) return
  taskData.value = data
}

const openTaskHandler = (item: TaskResponseSchema) => {
  patchOptions({
    attrs: { item: item }
  })
  open()
}

const initUser = async () => {
  const { data, error } = await userInfoUserGet()
  if (error) return
  userStore.setUser(data)
}
</script>

<style scoped>
.list {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.empty {
  margin-top: 2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #a7a7a7;
}
</style>
