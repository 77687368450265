<template>
  <div class="tree">
    <div class="content">
      <img
        ref="el"
        :src="isCompleteTree ? '/img/tree.png' : '/img/tree2.png'"
        alt=""
        :draggable="false"
      />
      <div
        class="slots"
        :style="{
          fontSize: fs + 'px',
          width: elSize?.width + 'px'
        }"
      >
        <img class="lock" src="/img/Main_screen_lock.png" alt="" />

        <div
          v-for="(item, i) in slots"
          @click="emits('clickSlot', i + 1)"
          class="slot"
          :key="i"
          :class="[
            `slot-${i + 1}`,
            {
              disabled: !isClickable,
              'slot-visible': isClickable
            }
          ]"
        >
          <div v-if="item && item.item" class="bg-n-loop">
            <img class="tbg" src="/img/toy-bg.png" alt="" />
            <img class="tloop" :src="`/img/toy-loop${loops[i + 1] || 1}.png`" alt="" />
          </div>
          <TreeToy
            v-if="item && item.item"
            :color="item.item.item_color || ToyColor.PURPLE"
            :grade="item.item.item_level"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ToyColor, type UserTreeSchema } from '@/api/client'
import TreeToy from './TreeToy.vue'
import { computed } from 'vue'

const isCompleteTree = computed(() => false)

const props = defineProps<{
  slots: Array<UserTreeSchema>
  isClickable?: boolean
}>()

const loops: Record<number, number> = {
  1: 2,
  2: 4,
  3: 4,
  4: 4,
  5: 4,
  6: 3,
  7: 3,
  8: 4,
  9: 1,
  10: 2,
  11: 4
}

const emits = defineEmits<{
  (e: 'clickSlot', index: number): void
}>()

const slots = computed((): Array<UserTreeSchema | null> => {
  return [...new Array(11)].map(
    (_, i) => props.slots.find((item) => item.used_slot_number - 1 === i) || null
  )
})

import { useResizeObserver } from '@vueuse/core'
import { ref } from 'vue'

const el = ref(null)
const fs = ref(0)
const elSize = ref<{ height: number; width: number }>()

useResizeObserver(el, (entries) => {
  const entry = entries[0]
  const { width, height } = entry.contentRect
  elSize.value = { height, width }
  fs.value = height / 32
})
</script>

<style scoped>
.tree {
  user-select: none;
}

.tree .lock {
  position: absolute;
  left: 51%;
  transform: translateX(-50%);
  top: 0;
  width: 6em;
  height: 6em;
}

.slot {
  position: absolute;
  left: 0;
  top: 0;
}
.slot.disabled {
  pointer-events: none;
}

.content {
  height: 100%;
  width: 100%;
}
.content img {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  transform: translateX(-50%);
}
.content img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  pointer-events: none;
}

.slots {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  transform: translateX(-50%);
}

.slot {
  position: absolute;
  left: 0;
  top: 0;
  width: 4em;
  height: 4em;
  border-radius: 50%;
}

.bg-n-loop {
  position: absolute;
  left: 0;
  top: 0;
  width: 4em;
  height: 4em;
}
.tbg {
  position: absolute !important;
  top: 1.1em !important;
}
.tloop {
  position: absolute !important;
  top: unset !important;
  bottom: 92%;
  width: 1.5em !important;
  height: 1.5em !important;
  z-index: 1;
}

.slot-visible {
  background: #d7ffffbd;
  border: 2px solid #00c9d0;
  cursor: pointer;
}

.slot-1,
.slot-1.bg-n-loop {
  left: 36%;
  top: 24%;
}
.slot-2,
.slot-2.bg-n-loop {
  left: 53%;
  top: 32%;
}
.slot-3,
.slot-3.bg-n-loop {
  left: 31%;
  top: 37%;
}
.slot-4,
.slot-4.bg-n-loop {
  left: 56%;
  top: 46%;
}
.slot-5,
.slot-5.bg-n-loop {
  left: 40%;
  top: 53%;
}
.slot-6,
.slot-6.bg-n-loop {
  left: 20%;
  top: 57%;
}
.slot-7,
.slot-7.bg-n-loop {
  left: 56%;
  top: 63%;
}
.slot-8,
.slot-8.bg-n-loop {
  left: 36%;
  top: 69%;
}
.slot-9,
.slot-9.bg-n-loop {
  left: 12%;
  top: 74%;
}
.slot-10,
.slot-10.bg-n-loop {
  left: 72%;
  top: 74%;
}
.slot-11,
.slot-11.bg-n-loop {
  left: 51%;
  top: 79%;
}
</style>
