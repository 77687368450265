<template>
  <div class="combo">
    <svg xmlns="http://www.w3.org/2000/svg" width="351" height="54" fill="none">
      <rect
        width="350.3"
        height="53.3"
        x=".35"
        y=".35"
        stroke="url(#paint0_linear_114_36)"
        stroke-width=".7"
        rx="7.65"
      />
      <defs>
        <linearGradient
          id="paint0_linear_114_36"
          x1="175.5"
          x2="175.5"
          y1="0"
          y2="54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#10AA20" stop-opacity=".18" />
          <stop offset="1" stop-color="#B9D937" />
        </linearGradient>
      </defs>
    </svg>
    <div class="content">
      <div class="name" @click="$router.push({ path: '/claim/dailycombo' })">Daily combo</div>
      <div class="numbers" @click="$router.push({ path: '/claim/dailycombo' })">
        <div class="num">
          <GradientBorderBox
            class="num-item"
            :class="{ 'active-item': false }"
            color1="#B9D937"
            color2="#10AA20"
            bg="#005559"
            :border-radius="4"
            :border-size="1"
          >
            <div>{{ dailyComboStore.numbers[1] }}</div>
          </GradientBorderBox>
          <GradientBorderBox
            class="num-item"
            :class="{ 'active-item': false }"
            color1="#B9D937"
            color2="#10AA20"
            bg="#005559"
            :border-radius="4"
            :border-size="1"
          >
            <div>{{ dailyComboStore.numbers[2] }}</div>
          </GradientBorderBox>
          <GradientBorderBox
            class="num-item"
            :class="{ 'active-item': false }"
            color1="#B9D937"
            color2="#10AA20"
            bg="#005559"
            :border-radius="4"
            :border-size="1"
          >
            <div>{{ dailyComboStore.numbers[3] }}</div>
          </GradientBorderBox>
        </div>
        <div class="timer">{{ dailyComboStore.remainingTime.string }}</div>
      </div>
      <div class="amount">
        <DailyComboClaimBtn />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDailyComboStore } from '@/stores/dailyCombo'
import GradientBorderBox from './GradientBorderBox.vue'
import DailyComboClaimBtn from './DailyComboClaimBtn.vue'

const dailyComboStore = useDailyComboStore()
</script>

<style scoped>
.combo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: aqua; */
  padding-left: 12px;
  padding-right: 12px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.name {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.numbers {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}
.num {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  gap: 4px;
}
.num-item {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #aba7b1;
}

.amount {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
</style>
