<template>
  <div class="colors">
    <div
      class="item red"
      :class="{ selected: model === ToyColor.RED }"
      @click="update(ToyColor.RED)"
    ></div>
    <div
      class="item yellow"
      :class="{ selected: model === ToyColor.YELLOW }"
      @click="update(ToyColor.YELLOW)"
    ></div>
    <div
      class="item blue"
      :class="{ selected: model === ToyColor.BLUE }"
      @click="update(ToyColor.BLUE)"
    ></div>
    <div
      class="item green"
      :class="{ selected: model === ToyColor.GREEN }"
      @click="update(ToyColor.GREEN)"
    ></div>
    <div
      class="item purple"
      :class="{ selected: model === ToyColor.PURPLE }"
      @click="update(ToyColor.PURPLE)"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { ToyColor } from '@/api/client'

const model = defineModel<ToyColor>({ required: true })

function update(color: ToyColor) {
  model.value = color
}
</script>

<style scoped>
.colors {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding-top: 20px;
}

.item {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.item.selected {
  transform: translateY(-20px);
}

.item.red {
  background: #b82d04;
  border: 1.5px solid #ff3a00;
}
.item.yellow {
  background: #e2ba24;
  border: 1.5px solid #ffdd5b;
}
.item.blue {
  background: #1c84ff;
  border: 1.5px solid #84bcff;
}
.item.green {
  background: #10aa20;
  border: 1.5px solid #30ff45;
}
.item.purple {
  background: #8423ff;
  border: 1.5px solid #c293ff;
}
</style>
