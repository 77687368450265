<template>
  <VueFinalModal
    content-class="absolute bottom-0 w-full p-4 bg-white dark:bg-gray-900 space-y-2 main"
    swipe-to-close="down"
    content-transition="vfm-slide-down"
    overlay-transition="vfm-fade"
  >
    <div class="modal-content">
      <button @click="emit('close')" class="btn-reset close">
        <IconClose />
      </button>
      <div class="content" style="padding: 2px 12px 40px">
        <div class="icon">
          <img v-if="item?.picture_url" :src="item.picture_url" alt="" />

          <svg
            v-else
            width="108"
            height="107"
            viewBox="0 0 108 107"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M94.125 71.3333V89.1667C94.125 91.629 92.129 93.625 89.6667 93.625H18.3333C15.8711 93.625 13.875 91.629 13.875 89.1667V80.25M94.125 71.3333V17.8333C94.125 15.3711 92.129 13.375 89.6667 13.375H18.3333C15.8711 13.375 13.875 15.3711 13.875 17.8333V80.25M94.125 71.3333L69.5279 54.9351C68.1967 54.0475 66.4918 53.9414 65.0607 54.6569L13.875 80.25"
              stroke="#A4A4A4"
              stroke-width="2"
              stroke-linejoin="round"
            />
            <path
              d="M36.1667 49.0417C41.0912 49.0417 45.0833 45.0496 45.0833 40.125C45.0833 35.2005 41.0912 31.2084 36.1667 31.2084C31.2421 31.2084 27.25 35.2005 27.25 40.125C27.25 45.0496 31.2421 49.0417 36.1667 49.0417Z"
              stroke="#A4A4A4"
              stroke-width="2"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="title">{{ item?.task_title }}</div>
        <div class="description">{{ item?.task_description }}</div>
        <div class="award">
          <img src="/img/coin-mini.png" width="24" height="25" alt="" />
          +{{ item?.task_award.toLocaleString('en-US') }}
        </div>
        <button class="btn-reset btn-active action-btn" @click="actionHandler">Visit</button>
        <button class="btn-reset btn-active claim-btn" @click="claimHandler" :disabled="false">
          Check
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import IconClose from '../icons/IconClose.vue'
import { onMounted, ref } from 'vue'
import {
  claimTaskTaskClaimTaskPost,
  completeSocialTaskTaskCompleteSocialTaskPost,
  type CantClaimTaskException,
  type ClaimTaskTaskClaimTaskPostData,
  type CompleteSocialTaskTaskCompleteSocialTaskPostData,
  type TaskNotFoundException,
  type TaskResponseSchema
} from '@/api/client'

const props = defineProps<{
  item?: TaskResponseSchema
}>()

const isClaiming = ref(false)

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'claim', list: TaskResponseSchema[]): void
}>()

onMounted(() => {})

const actionHandler = async () => {
  if (!props.item || !props.item?.url_for_social_task) return

  if (!props.item.tg_channel_id) {
    try {
      const payload: CompleteSocialTaskTaskCompleteSocialTaskPostData = {
        query: { task_id: props.item.id }
      }
      await completeSocialTaskTaskCompleteSocialTaskPost(payload)
    } catch (error) {
      console.log(error)
    }
  }
  openLink(props.item.url_for_social_task)
}

const openLink = (url: string) => {
  if (url.includes('t.me')) window.Telegram.WebApp.openTelegramLink(url)
  window.Telegram.WebApp.openLink(url)
}

const claimHandler = async () => {
  if (!props.item) return

  if (isClaiming.value) return
  isClaiming.value = true

  const payload: ClaimTaskTaskClaimTaskPostData = {
    query: { task_id: props.item.id }
  }

  const { data, error, response } = await claimTaskTaskClaimTaskPost(payload)

  if (error && response.status === 409) {
    const errData = error as CantClaimTaskException
    alert(errData.msg_for_user)
    return
  }

  if (error && response.status === 404) {
    const errData = error as TaskNotFoundException
    alert(errData.msg_for_user)
    return
  }

  if (error) return

  isClaiming.value = false

  emit('claim', data)
}
</script>

<style>
.main {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>

<style scoped>
.modal-content {
  user-select: none;
  padding: 1rem;
  background-color: aliceblue;

  margin-top: 0.5rem;

  box-sizing: border-box;
  width: 100%;
  color: white;
  background-color: #005559;
  border-radius: 1rem 1rem 0 0;
  outline: 2px solid #b9d937;

  position: relative;
}

.close {
  position: absolute;
  font-size: 24px;
  top: 14px;
  right: 14px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon {
  margin-bottom: 8px;
  height: 107px;
  width: 107px;
}
.icon > * {
  object-fit: contain;
  height: 107px;
  width: 107px;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 4px;
}
.description {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.award {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.action-btn {
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 24px;
}
.claim-btn {
  margin-top: 24px;

  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
