<template>
  <div class="friend">
    <div class="top">
      <div class="content">
        <div class="name">{{ item.name }}</div>
        <div class="data">
          <div class="status">
            <img :src="item.league_picture_url" height="24" width="24" alt="" />
            {{ item.league }}
          </div>

          <div class="value">
            <img src="/img/coin-mini.png" width="14" height="15" alt="" />
            {{ item.balance.toLocaleString('en-US') }}
          </div>
        </div>
      </div>

      <div class="claim" v-if="item.can_claim">
        <button class="claim-btn btn btn-active" @click="claimHandler" :disabled="!item.can_claim">
          Claim
        </button>
      </div>
      <div v-else class="bonus">+{{ item.total_earned_from_me.toLocaleString('en-US') }}</div>
    </div>

    <ProgressBar :val="progress" />
  </div>
</template>

<script setup lang="ts">
import type { FriendSchema } from '@/api/client'
import ProgressBar from './ProgressBar.vue'
import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/user'

const isClaiming = ref(false)

const userStore = useUserStore()

const progress = computed((): number => {
  const earned = userStore.user?.total_earned
  const nextTarget = props.item.next_league_reach_amount

  if (earned === undefined) return 0
  if (nextTarget === null) return 1
  return earned / nextTarget
})

const emit = defineEmits<{
  (e: 'claim', item: FriendSchema): void
}>()

const claimHandler = () => {
  if (isClaiming.value) return
  isClaiming.value = true
  emit('claim', props.item)
}

const props = defineProps<{
  item: FriendSchema
}>()
</script>

<style scoped>
.friend {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;

  background: rgba(0, 85, 89, 0.9);
  border: 1px solid rgba(171, 171, 171, 0.27);
  border-radius: 12px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
}
.content {
}
.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.data {
  display: flex;
  align-items: center;
  gap: 20px;
}
.status {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #a7a7a7;
  display: flex;
  align-items: center;
  gap: 4px;
}
.value {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #a7a7a7;
  display: flex;
  align-items: center;
  gap: 4px;
}
.claim {
}
.bonus {
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #e2ba24;
}

.claim-btn {
  padding: 6.5px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
</style>
