<template>
  <div class="league">
    <img
      class="league-img"
      v-if="userStore.user?.league_icon_url"
      :src="userStore.user?.league_icon_url"
      alt=""
    />
    <svg
      v-else
      width="20"
      height="20"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.375 24.6667V30.8333C32.375 31.6848 31.6848 32.375 30.8333 32.375H6.16667C5.31523 32.375 4.625 31.6848 4.625 30.8333V27.75M32.375 24.6667V6.16667C32.375 5.31523 31.6848 4.625 30.8333 4.625H6.16667C5.31523 4.625 4.625 5.31523 4.625 6.16667V27.75M32.375 24.6667L23.8695 18.9963C23.4091 18.6893 22.8196 18.6526 22.3247 18.9001L4.625 27.75"
        stroke="#A4A4A4"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M12.3333 16.9583C14.0362 16.9583 15.4167 15.5778 15.4167 13.875C15.4167 12.1721 14.0362 10.7916 12.3333 10.7916C10.6305 10.7916 9.25 12.1721 9.25 13.875C9.25 15.5778 10.6305 16.9583 12.3333 16.9583Z"
        stroke="#A4A4A4"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
    {{ userStore.user?.league?.league_name }}
    <IconLeagueArrow />
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/stores/user'
import IconLeagueArrow from './icons/IconLeagueArrow.vue'

const userStore = useUserStore()
</script>

<style scoped>
.league {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.league-img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
</style>
