<template>
  <div class="page">
    <template v-if="!isLoading">
      <div class="list" v-if="taskData?.length">
        <TaskItem
          v-for="(item, i) of taskData"
          :key="i"
          :item="item"
          :friends-amoutn="friendsAmount"
          @claim="claimHandler"
        />
      </div>

      <div class="empty" v-else>Your task list is empty</div>
    </template>
    <BackButton @click="$router.push({ name: 'home' })" />
  </div>
</template>

<script setup lang="ts">
import {
  claimTaskTaskClaimTaskPost,
  friendsUserFriendsGet,
  getTaskTaskGetTaskGet,
  TaskType,
  type ClaimTaskTaskClaimTaskPostData,
  type GetTaskTaskGetTaskGetData,
  type TaskResponseSchema
} from '@/api/client'
import TaskItem from '@/components/TaskItem.vue'
import { useFriendsStore } from '@/stores/friends'
import { computed, onMounted, ref } from 'vue'
import { BackButton } from 'vue-tg'

const taskData = ref<Array<TaskResponseSchema>>()
const friendsStore = useFriendsStore()

const isLoading = ref(true)
const isClaiming = ref(false)

onMounted(async () => {
  if (friendsStore.friends === null) {
    await initFriends()
  }
  await initTasks()
  isLoading.value = false
})

const friendsAmount = computed(() => {
  return friendsStore.friends?.friends_amount ?? NaN
})

const initTasks = async () => {
  const payload: GetTaskTaskGetTaskGetData = {
    query: { type_task: TaskType.INVITE_FRIEND_TASK }
  }
  const { data, error } = await getTaskTaskGetTaskGet(payload)
  if (error) return
  taskData.value = data
}

const initFriends = async () => {
  const { data, error } = await friendsUserFriendsGet()
  if (error) return
  friendsStore.setFriends(data)
}

const claimHandler = async (item: TaskResponseSchema) => {
  if (isClaiming.value) return
  isClaiming.value = true

  const payload: ClaimTaskTaskClaimTaskPostData = {
    query: { task_id: item.id }
  }
  const { data, error } = await claimTaskTaskClaimTaskPost(payload)
  if (error) return
  taskData.value = data
  isClaiming.value = false
}
</script>

<style scoped>
.list {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.empty {
  margin-top: 2rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #a7a7a7;
}
</style>
