<template>
  <div
    class="balance"
    :class="{
      show: isShow
    }"
  >
    <img src="/img/coin.png" height="51" width="54" alt="" />
    <div class="balance-val">
      {{
        (isTapMode ? formattedNumber : userStore.user?.coin_balance || 0).toLocaleString('uk-UA')
      }}
      <Transition name="slide-fade">
        <div v-if="dailyComboStore.displayNumber !== null" class="daily-number">
          {{ dailyComboStore.displayNumber }}
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDailyComboStore } from '@/stores/dailyCombo'
import { useTapItStore } from '@/stores/tapIt'
import { useUserStore } from '@/stores/user'
import { computed, onMounted } from 'vue'

const dailyComboStore = useDailyComboStore()

const userStore = useUserStore()
const tapItStore = useTapItStore()
const balance = computed(() => {
  return tapItStore.userInfo?.balance || 0
})
const userBalance = computed(() => {
  return balance.value + userStore.taps
})

const formattedNumber = computed(() => {
  return new Intl.NumberFormat('ru-RU').format(userBalance.value)
})

defineProps<{
  isTapMode?: boolean
  isShow?: boolean
}>()
</script>

<style scoped>
.balance {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  font-weight: 500;
  font-size: 30px;
  line-height: 35px;

  position: relative;
  opacity: 0;
  transition: all 0.3s;
}

.show {
  opacity: 1;
}

.balance-val {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  font-weight: 500;
  font-size: 30px;
  line-height: 35px;

  position: relative;
}

.daily-number {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #30ff45;
  border-radius: 8px;

  position: absolute;
  left: calc(100% + 1rem);
  font-weight: 500;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
