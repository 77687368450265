import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import {
  type BoostTurnedResponse,
  type StartAutoFarmTapItStartAutoFarmPostResponse,
  type UserResponseSchema
} from '@/api/client'
import { defineStore } from 'pinia'

type State = {
  user?: UserResponseSchema
  taps: number

  _currentDate?: any
  _timerId?: number
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    user: undefined,
    taps: 0,

    _currentDate: undefined,
    _timerId: undefined
  }),

  getters: {
    autofarm: (state) => {},

    remainingFarmingTime(state) {
      dayjs.extend(utc)
      dayjs.extend(timezone)
      dayjs.extend(duration)
      const timestampStart = Math.floor(state.user?.start_farming_date || 0)
      const timestampEnd = Math.floor(state.user?.end_farming_date || 0)
      const startDate = dayjs.unix(timestampStart)
      const endDate = dayjs.unix(timestampEnd)
      const diff = endDate.diff(state._currentDate)

      const durationObj = dayjs.duration(diff)
      if (durationObj.asSeconds() < 0) {
        return {
          seconds: durationObj.asSeconds(),
          string: '00:00:00',
          obj: { h: 0, m: 0, s: 0 }
        }
      }

      const hours = String(durationObj.hours()).padStart(2, '0')
      const minutes = String(durationObj.minutes()).padStart(2, '0')
      const seconds = String(durationObj.seconds()).padStart(2, '0')
      const hoursValue = durationObj.hours()
      const minutesValue = durationObj.minutes()
      const secondsValue = durationObj.seconds()
      const remainingTime = `${hours}:${minutes}:${seconds}`

      // const progress = 1 - durationObj.asSeconds() / (endDate.diff(startDate) / 1000)
      return {
        // progress: progress,
        seconds: durationObj.asSeconds(),
        string: remainingTime,
        obj: { h: hoursValue, m: minutesValue, s: secondsValue }
      }
    }
  },

  actions: {
    setUser(data: UserResponseSchema) {
      this.user = data
      this.refreshCurrentDate()
    },

    setUserBoostData(data: BoostTurnedResponse) {
      if (!this.user) return

      const { coin_boost_end_date } = data

      // this.user.last_use_energy_boost
      // this.user.last_use_coin_boost
      this.user.coin_boost_end_date = coin_boost_end_date
    },

    reduceBoostCoinActivations() {
      if (!this.user || !this.user.coin_boost) return
      this.user.coin_boost -= 1
    },
    reduceBoostEnergyActivations() {
      if (!this.user || !this.user.energy_boost) return
      this.user.energy_boost -= 1
    },

    setWaitingAutoFarmForUser(data: StartAutoFarmTapItStartAutoFarmPostResponse) {
      if (!this.user) return
      const { amount_will_earned, date_end, date_start } = data

      this.user.amount_will_earned = amount_will_earned
      this.user.start_farming_date = date_start
      this.user.end_farming_date = date_end

      this.user.auto_farming_status = true // 👀 now manually set status
    },

    // TAP
    updateUserBalance(balance: number) {
      if (this.user) {
        this.user.coin_balance = balance
      }
    },
    incrementBalance(step: number) {
      this.taps += step
    },
    anulateTaps() {
      this.taps = 0
      this.refreshCurrentDate()
    },

    // autofram
    refreshCurrentDate() {
      this._currentDate = dayjs()
      window.clearInterval(this._timerId)
      this._timerId = setInterval(() => {
        this._currentDate = dayjs()
      }, 1000)
    }
  }
})
