import {
  ItemType,
  ToyColor,
  type GetShopItemsShopGetResponse,
  type GetUserInventoryUserInventoryGetResponse,
  type ShopSchema,
  type UserInventorySchema_Output
} from '@/api/client'
import { defineStore } from 'pinia'

export type InvItem = {
  storeItem: ShopSchema
  invItems: UserInventorySchema_Output[]
}

type State = {
  // private

  // local

  // from server
  inventory: UserInventorySchema_Output[]
}

export const useInventoryStore = defineStore('inventory', {
  state: (): State => ({
    inventory: []
  }),

  getters: {
    balls: (state): ShopSchema[] => {
      const balls = state.inventory
        .filter((item) => !!item)
        .map((item) => item.item)
        .filter((item) => item?.item_type === ItemType.TOY)

      // TODO check as  ShopSchema[]
      return balls as ShopSchema[]
    },
    ballsStackedInventory: (state): InvItem[] => {
      const balls = state.inventory
        .filter((item) => item.item?.item_type === ItemType.TOY)
        .filter((item) => !!item.item)
        .filter((item) => !item.used_slot_number)

      const ballsStackedInventory: InvItem[] = []

      balls.forEach((ball) => {
        const invItem = ballsStackedInventory.find(
          (invItem) => invItem.storeItem.id === ball.item!.id
        )
        if (invItem) {
          invItem.invItems.push(ball)
        } else {
          ballsStackedInventory.push({
            storeItem: ball.item!,
            invItems: [ball]
          })
        }
      })

      ballsStackedInventory.sort((a, b) => b.storeItem.item_level - a.storeItem.item_level)

      return ballsStackedInventory
    },
    boosers: (state): UserInventorySchema_Output[] => {
      const boosters = state.inventory
        .filter((item) => item.item?.item_type === ItemType.BOOSTER)
        .filter((item) => !!item.item)
      return boosters
    },
    getInventBallByShopId() {
      return (shopId: number) => {
        return this.ballsStackedInventory.find((item) => item.storeItem.id === shopId)?.invItems[0]
      }
    }
  },

  actions: {
    setInvent(data: GetUserInventoryUserInventoryGetResponse) {
      this.inventory = data
    }
  }
})
