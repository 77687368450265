<template>
  <div :style="boxStyle">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, type CSSProperties } from 'vue'

const props = defineProps<{
  color1: string
  color2: string
  bg: string
  borderRadius: number
  borderSize: number
}>()

const boxStyle = computed((): CSSProperties => {
  return {
    border: `${props.borderSize}px solid transparent`,
    'border-radius': `${props.borderRadius}px`,
    background: `linear-gradient(to right, ${props.bg}, ${props.bg}), linear-gradient(to bottom, ${props.color1}, ${props.color2})`,
    'background-clip': 'padding-box, border-box',
    'background-origin': 'padding-box, border-box'
  }
})
</script>

<style scoped></style>
