<template>
  <div class="gradient-box">
    <div class="content-wrap">
      <div class="link">
        <div class="name">My invite link:</div>
        <div class="url">{{ copied ? 'Copied!' : link }}</div>
      </div>

      <MainButton @click="copy(source)">
        <span style="font-size: 14px; font-weight: 700">Copy</span>
      </MainButton>
    </div>

    <div class="content-wrap2">
      <a :href="tlink" target="_blank" rel="noopener">
        <MainButton @click="copy(source)"> Send </MainButton>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import MainButton from '../MainButton.vue'
import { useClipboard } from '@vueuse/core'

const tlink = computed(() => {
  return `https://t.me/share/url?url=${props.link}`
})

const props = defineProps<{
  link: string
}>()
const source = ref(props.link)
const { copy, copied } = useClipboard({ source })
</script>

<style scoped>
.d {
  /* invite_link */

  box-sizing: border-box;

  padding: 17px 10px;
  gap: 10px;

  border-radius: 8px;
}

.gradient-box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* width: 90%; */
  max-width: 99%;
  margin: auto;
  /* max-width: 22em; */
  position: relative;
  /* padding: 30% 2em; */
  box-sizing: border-box;
  background: #014341;
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 7px;
}
.gradient-box:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -2px;
  border-radius: 8px;
  background: linear-gradient(to top, #b9d937, #10aa1f34);
}

.content-wrap {
  padding: 18px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-wrap2 {
  padding: 12px 10px 10px;
}
.content-wrap2 > * {
  display: block;
}

.link {
}
.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.url {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #aba7b1;

  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
