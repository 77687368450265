import {
  BoosterType,
  ItemType,
  ToyColor,
  type GetShopItemsShopGetResponse,
  type ShopSchema
} from '@/api/client'
import { defineStore } from 'pinia'
import { useUserStore } from './user'

type State = {
  _userStore: ReturnType<typeof useUserStore>
  // private

  // local

  // from server
  shop: ShopSchema[]
}

export const useShopStore = defineStore('shop', {
  state: (): State => ({
    _userStore: useUserStore(),
    shop: []
  }),

  getters: {
    balls: (state): Record<ToyColor, ShopSchema[]> => {
      const balls = state.shop.filter((item) => item.item_type === ItemType.TOY)
      return {
        RED: balls.filter((item) => item.item_color === ToyColor.RED) || [],
        YELLOW: balls.filter((item) => item.item_color === ToyColor.YELLOW) || [],
        BLUE: balls.filter((item) => item.item_color === ToyColor.BLUE) || [],
        GREEN: balls.filter((item) => item.item_color === ToyColor.GREEN) || [],
        PURPLE: balls.filter((item) => item.item_color === ToyColor.PURPLE) || []
      }
    },
    boosters: (state): ShopSchema[] => {
      const boosters = state.shop.filter((item) => item.item_type === ItemType.BOOSTER)
      return boosters
    },

    boostersStacked: (state): Record<BoosterType, ShopSchema[]> => {
      const boosters = state.shop
        .filter((item) => item.item_type === ItemType.BOOSTER)
        .sort((a, b) => a.item_level - b.item_level)

      const milk = boosters.filter((item) => item.booster_type === BoosterType.ENERGY_MILK)
      const magicTap = boosters.filter((item) => item.booster_type === BoosterType.MAGIC_TAP)
      const rechargingSpeed = boosters.filter(
        (item) => item.booster_type === BoosterType.RECHARGING_SPEED
      )

      return { ENERGY_MILK: milk, MAGIC_TAP: magicTap, RECHARGING_SPEED: rechargingSpeed }
    },

    nextUserBooster(state): Record<BoosterType, ShopSchema | undefined> {
      const nextMilk = this.boostersStacked.ENERGY_MILK.find((item) => {
        if (!this._userStore.user) return

        return item.item_level === this._userStore.user.energy_milk_level + 1
      })
      const nextTap = this.boostersStacked.MAGIC_TAP.find((item) => {
        if (!this._userStore.user) return
        return item.item_level === this._userStore.user.magic_tap_level + 1
      })
      const nextRechargin = this.boostersStacked.RECHARGING_SPEED.find((item) => {
        if (!this._userStore.user) return
        return item.item_level === this._userStore.user.recharging_speed_level + 1
      })

      return {
        ENERGY_MILK: nextMilk,
        MAGIC_TAP: nextTap,
        RECHARGING_SPEED: nextRechargin
      }
    }
  },

  actions: {
    setShop(data: GetShopItemsShopGetResponse) {
      this.shop = data
    }
  }
})
