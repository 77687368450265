<template>
  <div class="prgr">
    <div
      class="val"
      :style="{
        width: value * 100 + '%'
      }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  val: number // 0..1
}>()

const value = computed(() => {
  return Math.max(0, Math.min(1, props.val))
})
</script>

<style scoped>
.prgr {
  height: 16px;
  width: 100%;
  background: rgba(226, 186, 36, 0.59);
  border-radius: 4px;

  box-shadow: inset -1.67px 1.67px 1.67px rgba(168, 255, 255, 0.25);
}
.val {
  box-shadow: inset 0 1.67px 1.67px rgba(168, 255, 255, 0.25);

  border-radius: 4px;
  height: 16px;
  background: #e2ba24;
}
</style>
