import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'

const TIMEZONE = 'Europe/Kyiv'

type State = {
  state: any | null
  _currentDate?: any
  _timerId?: number
}

type Countdown = {
  isNewYear: boolean
  counter: {
    day: string
    hrs: string
    min: string
    sec: string
  }
}

export const useCounterStore = defineStore('counter', {
  state: (): State => ({
    state: null,
    _currentDate: dayjs(),
    _timerId: undefined
  }),

  getters: {
    localNewYear(): Countdown {
      dayjs.extend(utc)
      dayjs.extend(timezone)
      dayjs.extend(duration)
      const now = this._currentDate
      const newYear = dayjs(`${now.year() + 1}-01-01 00:00:00`)
      const diff = newYear.diff(now)
      const durationObj = dayjs.duration(diff)
      return {
        isNewYear: diff <= 0,
        counter: {
          day: Math.floor(durationObj.asDays()).toString(),
          hrs: String(durationObj.hours()).padStart(2, '0'),
          min: String(durationObj.minutes()).padStart(2, '0'),
          sec: String(durationObj.seconds()).padStart(2, '0')
        }
      }
    },
    timezoneNewYear(): Countdown {
      dayjs.extend(utc)
      dayjs.extend(timezone)
      dayjs.extend(duration)
      const now = this._currentDate.tz(TIMEZONE)
      const newYear = dayjs.tz(`${now.year() + 1}-01-01 00:00:00`, TIMEZONE)
      const diff = newYear.diff(now)
      const durationObj = dayjs.duration(diff)
      return {
        isNewYear: diff <= 0,
        counter: {
          day: Math.floor(durationObj.asDays()).toString(),
          hrs: String(durationObj.hours()).padStart(2, '0'),
          min: String(durationObj.minutes()).padStart(2, '0'),
          sec: String(durationObj.seconds()).padStart(2, '0')
        }
      }
    }
  },

  actions: {
    refreshCurrentDate() {
      this._currentDate = dayjs()
      if (this._timerId) {
        clearInterval(this._timerId)
      }
      this._timerId = setInterval(() => {
        this._currentDate = dayjs()
      }, 1000)
    }
  }
})
